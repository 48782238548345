import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'

export const LinkUnderline = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const LinkColor = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export const Base = styled(NavLink)<{
  width?: string
  height?: string
  padding?: string
  margin?: string
  display?: string
  fontSize?: string
}>`
  font-family: Inter;
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  cursor: ${(props) => props.theme.buttons.cursor};
  width: ${({ width }) => width ?? 'auto'};
  margin: ${({ margin }) => margin ?? '0 8px'};
  white-space: nowrap;
  text-align: center;
  ${({ height }) => (height ? `height: ${height};` : '')}
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  ${({ display }) => (display ? `display: ${display};` : '')}
  position: relative;

  &.active,
  &:focus {
    outline: none;
    font-weight: 600;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: none;
    &.active {
      border-bottom: none;
      text-decoration: none;
    }
  `};
`

export const PrimaryLink = styled(Base)`
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    fill: ${({ theme }) => theme.colors.secondary};
  }
`
export const SecondaryLink = styled(Base)`
  color: ${({ theme }) => theme.colors.secondary};
  fill: ${({ theme }) => theme.colors.secondary};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    fill: ${({ theme }) => theme.colors.primary};
  }
`

export const ButtonLink = styled(Base)`
  background-color: ${(props) => props.theme.buttons.primary.bg};
  padding: ${({ padding }) => padding ?? '7px 24px'};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: ${(props) => props.theme.buttons.primary.borderRadius};
  font-weight: 500;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

export const SecondaryButtonLink = styled(Base)`
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 7px 24px;
  color: ${({ theme }) => theme.colors.text};
  border-radius: ${(props) => props.theme.buttons.primary.borderRadius};
  font-weight: 500;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
  }
`

export const NavbarLink = styled(Base)`
  &:hover {
    opacity: 0.7;
  }
`
