export function PoweredByAvalancheIcon({ height = '988', color = 'black' }: { height?: string; color?: string }) {
  return (
    <svg height={height} viewBox="0 0 2875 988" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="3.68016"
        y="3.68016"
        width="2866.82"
        height="980.64"
        rx="143.526"
        fill="white"
        stroke="black"
        strokeWidth={'7.36033'}
      />
      <path
        d="M1216.48 643.099H1268.64L1242.98 562.278L1216.48 643.099ZM1219.13 515.318H1267.67L1340.47 720.715H1293.89L1280.64 678.493H1204.9L1190.67 720.715H1145.76L1219.13 515.318Z"
        fill={color}
      />
      <path
        d="M1440.9 568.827H1483.59L1428.77 720.715H1386.79L1332.25 568.827H1376.88L1408.55 680.862L1440.9 568.827Z"
        fill={color}
      />
      <path
        d="M1584.29 646.861C1581.78 648.441 1579.23 649.741 1576.62 650.763C1574.11 651.692 1570.63 652.575 1566.16 653.411L1557.24 655.083C1548.87 656.569 1542.87 658.381 1539.24 660.517C1533.11 664.14 1530.04 669.761 1530.04 677.378C1530.04 684.16 1531.9 689.083 1535.62 692.149C1539.43 695.122 1544.03 696.608 1549.42 696.608C1557.98 696.608 1565.84 694.1 1573 689.083C1580.25 684.067 1584.02 674.916 1584.29 661.632V646.861ZM1560.16 628.328C1567.51 627.399 1572.76 626.238 1575.93 624.845C1581.6 622.429 1584.43 618.667 1584.43 613.558C1584.43 607.333 1582.25 603.06 1577.88 600.738C1573.6 598.322 1567.28 597.115 1558.91 597.115C1549.52 597.115 1542.87 599.437 1538.96 604.082C1536.17 607.519 1534.31 612.164 1533.38 618.017H1495.03C1495.86 604.732 1499.58 593.817 1506.19 585.27C1516.69 571.893 1534.73 565.204 1560.3 565.204C1576.95 565.204 1591.73 568.502 1604.66 575.098C1617.58 581.694 1624.05 594.142 1624.05 612.443V682.116C1624.05 686.947 1624.14 692.799 1624.33 699.674C1624.6 704.876 1625.39 708.406 1626.7 710.264C1628 712.122 1629.95 713.655 1632.55 714.862V720.715H1589.32C1588.11 717.649 1587.27 714.77 1586.81 712.075C1586.34 709.381 1585.97 706.316 1585.69 702.879C1580.2 708.824 1573.88 713.887 1566.72 718.067C1558.17 722.991 1548.49 725.453 1537.71 725.453C1523.95 725.453 1512.56 721.551 1503.54 713.748C1494.61 705.851 1490.15 694.704 1490.15 680.305C1490.15 661.632 1497.35 648.115 1511.77 639.755C1519.67 635.203 1531.29 631.951 1546.64 630L1560.16 628.328Z"
        fill={color}
      />
      <path d="M1694.35 720.715H1654.6V515.318H1694.35V720.715Z" fill={color} />
      <path
        d="M1811.38 646.861C1808.87 648.441 1806.31 649.741 1803.71 650.763C1801.2 651.692 1797.71 652.575 1793.25 653.411L1784.32 655.083C1775.95 656.569 1769.95 658.381 1766.33 660.517C1760.19 664.14 1757.12 669.761 1757.12 677.378C1757.12 684.16 1758.98 689.083 1762.7 692.149C1766.51 695.122 1771.11 696.608 1776.51 696.608C1785.06 696.608 1792.92 694.1 1800.08 689.083C1807.33 684.067 1811.1 674.916 1811.38 661.632V646.861ZM1787.25 628.328C1794.59 627.399 1799.85 626.238 1803.01 624.845C1808.68 622.429 1811.52 618.667 1811.52 613.558C1811.52 607.333 1809.33 603.06 1804.96 600.738C1800.68 598.322 1794.36 597.115 1785.99 597.115C1776.6 597.115 1769.95 599.437 1766.05 604.082C1763.26 607.519 1761.4 612.164 1760.47 618.017H1722.11C1722.95 604.732 1726.67 593.817 1733.27 585.27C1743.78 571.893 1761.82 565.204 1787.39 565.204C1804.03 565.204 1818.82 568.502 1831.74 575.098C1844.67 581.694 1851.13 594.142 1851.13 612.443V682.116C1851.13 686.947 1851.22 692.799 1851.41 699.674C1851.69 704.876 1852.48 708.406 1853.78 710.264C1855.08 712.122 1857.03 713.655 1859.64 714.862V720.715H1816.4C1815.19 717.649 1814.35 714.77 1813.89 712.075C1813.42 709.381 1813.05 706.316 1812.77 702.879C1807.29 708.824 1800.96 713.887 1793.8 718.067C1785.25 722.991 1775.58 725.453 1764.79 725.453C1751.03 725.453 1739.64 721.551 1730.62 713.748C1721.69 705.851 1717.23 694.704 1717.23 680.305C1717.23 661.632 1724.44 648.115 1738.85 639.755C1746.75 635.203 1758.38 631.951 1773.72 630L1787.25 628.328Z"
        fill={color}
      />
      <path
        d="M1965.09 565.204C1980.8 565.204 1993.64 569.338 2003.58 577.606C2013.63 585.781 2018.65 599.391 2018.65 618.435V720.715H1977.92V628.328C1977.92 620.339 1976.85 614.208 1974.71 609.935C1970.81 602.131 1963.37 598.229 1952.4 598.229C1938.91 598.229 1929.66 603.943 1924.64 615.369C1922.04 621.407 1920.73 629.118 1920.73 638.501V720.715H1881.12V569.106H1919.48V591.262C1924.59 583.459 1929.43 577.838 1933.98 574.401C1942.17 568.27 1952.54 565.204 1965.09 565.204Z"
        fill={color}
      />
      <path
        d="M2140.56 623.312C2139.81 617.645 2137.91 612.536 2134.84 607.984C2130.38 601.852 2123.45 598.787 2114.06 598.787C2100.67 598.787 2091.51 605.429 2086.58 618.713C2083.98 625.774 2082.67 635.156 2082.67 646.861C2082.67 658.009 2083.98 666.974 2086.58 673.755C2091.32 686.389 2100.25 692.706 2113.36 692.706C2122.66 692.706 2129.26 690.198 2133.17 685.182C2137.07 680.165 2139.44 673.662 2140.28 665.673H2180.87C2179.94 677.75 2175.57 689.176 2167.76 699.952C2155.3 717.324 2136.84 726.01 2112.38 726.01C2087.93 726.01 2069.94 718.764 2058.41 704.272C2046.88 689.78 2041.11 670.968 2041.11 647.837C2041.11 621.733 2047.48 601.434 2060.22 586.942C2072.96 572.45 2090.53 565.204 2112.94 565.204C2132 565.204 2147.58 569.478 2159.67 578.024C2171.85 586.571 2179.05 601.667 2181.29 623.312H2140.56Z"
        fill={color}
      />
      <path
        d="M2286.88 564.926C2296.27 564.926 2304.78 566.551 2312.4 569.803C2320.12 573.054 2326.44 578.024 2331.37 584.713C2335.56 590.38 2338.11 596.232 2339.04 602.271C2339.97 608.216 2340.44 617.97 2340.44 631.533V720.715H2299.85V628.328C2299.85 620.153 2298.45 613.558 2295.66 608.541C2292.04 601.481 2285.16 597.951 2275.02 597.951C2264.51 597.951 2256.52 601.481 2251.03 608.541C2245.64 615.508 2242.94 625.495 2242.94 638.501V720.715H2203.33V516.015H2242.94V588.615C2248.71 579.789 2255.36 573.658 2262.89 570.221C2270.51 566.691 2278.51 564.926 2286.88 564.926Z"
        fill={color}
      />
      <path
        d="M2431.94 597.533C2422.74 597.533 2415.58 600.413 2410.46 606.172C2405.44 611.932 2402.28 619.735 2400.98 629.582H2462.77C2462.12 619.085 2458.91 611.142 2453.14 605.754C2447.47 600.273 2440.4 597.533 2431.94 597.533ZM2431.94 564.786C2444.59 564.786 2455.98 567.155 2466.11 571.893C2476.25 576.631 2484.62 584.109 2491.22 594.328C2497.17 603.339 2501.03 613.79 2502.8 625.681C2503.82 632.648 2504.24 642.681 2504.05 655.78H2400C2400.56 671.015 2405.35 681.698 2414.37 687.829C2419.85 691.638 2426.46 693.542 2434.17 693.542C2442.36 693.542 2449 691.22 2454.12 686.575C2456.91 684.067 2459.37 680.583 2461.51 676.124H2502.1C2501.08 685.135 2496.38 694.286 2488.01 703.575C2474.99 718.346 2456.77 725.731 2433.34 725.731C2414 725.731 2396.93 719.507 2382.15 707.059C2367.36 694.611 2359.97 674.359 2359.97 646.304C2359.97 620.014 2366.62 599.855 2379.92 585.828C2393.31 571.8 2410.65 564.786 2431.94 564.786Z"
        fill={color}
      />
      <path
        d="M1278.66 263.122H1349.09C1363.01 263.122 1374.23 267.061 1382.76 274.937C1391.28 282.743 1395.54 293.743 1395.54 307.935C1395.54 320.141 1391.74 330.786 1384.14 339.869C1376.54 348.881 1364.86 353.388 1349.09 353.388H1299.86V419.49H1278.66V263.122ZM1374.13 308.042C1374.13 296.546 1369.87 288.74 1361.34 284.624C1356.65 282.424 1350.23 281.324 1342.06 281.324H1299.86V335.505H1342.06C1351.58 335.505 1359.28 333.482 1365.18 329.437C1371.14 325.392 1374.13 318.261 1374.13 308.042Z"
        fill={color}
      />
      <path
        d="M1460.54 407.142C1473.26 407.142 1481.96 402.352 1486.64 392.772C1491.4 383.121 1493.78 372.406 1493.78 360.626C1493.78 349.981 1492.08 341.324 1488.67 334.653C1483.27 324.151 1473.97 318.899 1460.75 318.899C1449.03 318.899 1440.51 323.37 1435.18 332.311C1429.86 341.253 1427.19 352.039 1427.19 364.671C1427.19 376.805 1429.86 386.918 1435.18 395.008C1440.51 403.097 1448.96 407.142 1460.54 407.142ZM1461.29 302.187C1475.99 302.187 1488.42 307.084 1498.58 316.877C1508.74 326.67 1513.81 341.075 1513.81 360.094C1513.81 378.473 1509.34 393.659 1500.39 405.652C1491.44 417.645 1477.55 423.641 1458.73 423.641C1443.03 423.641 1430.57 418.355 1421.33 407.781C1412.1 397.137 1407.48 382.873 1407.48 364.99C1407.48 345.83 1412.35 330.573 1422.08 319.219C1431.81 307.865 1444.88 302.187 1461.29 302.187Z"
        fill={color}
      />
      <path
        d="M1541.2 305.487L1563.15 395.327L1585.42 305.487H1606.94L1629.31 394.795L1652.65 305.487H1671.83L1638.69 419.49H1618.77L1595.54 331.247L1573.06 419.49H1553.13L1520.21 305.487H1541.2Z"
        fill={color}
      />
      <path
        d="M1733.2 302.933C1741.3 302.933 1749.15 304.849 1756.75 308.681C1764.35 312.442 1770.14 317.338 1774.12 323.37C1777.95 329.118 1780.51 335.824 1781.79 343.488C1782.92 348.739 1783.49 357.113 1783.49 368.609H1699.85C1700.21 380.176 1702.94 389.472 1708.06 396.498C1713.17 403.452 1721.09 406.929 1731.82 406.929C1741.83 406.929 1749.82 403.63 1755.79 397.03C1759.2 393.198 1761.61 388.763 1763.03 383.724H1781.89C1781.4 387.911 1779.73 392.595 1776.89 397.775C1774.12 402.885 1770.99 407.071 1767.51 410.336C1761.69 416.013 1754.48 419.845 1745.88 421.832C1741.26 422.967 1736.04 423.535 1730.22 423.535C1716.01 423.535 1703.97 418.39 1694.1 408.1C1684.23 397.74 1679.29 383.263 1679.29 364.671C1679.29 346.362 1684.26 331.495 1694.21 320.07C1704.15 308.645 1717.15 302.933 1733.2 302.933ZM1763.78 353.388C1763 345.085 1761.19 338.45 1758.35 333.482C1753.09 324.257 1744.32 319.644 1732.03 319.644C1723.22 319.644 1715.83 322.838 1709.87 329.225C1703.9 335.54 1700.74 343.595 1700.39 353.388H1763.78Z"
        fill={color}
      />
      <path
        d="M1803.31 305.487H1821.53V325.18C1823.02 321.348 1826.68 316.699 1832.51 311.235C1838.33 305.7 1845.04 302.933 1852.64 302.933C1853 302.933 1853.6 302.968 1854.46 303.039C1855.31 303.11 1856.76 303.252 1858.82 303.465V323.689C1857.69 323.476 1856.62 323.335 1855.63 323.264C1854.7 323.193 1853.67 323.157 1852.54 323.157C1842.88 323.157 1835.45 326.28 1830.27 332.524C1825.08 338.698 1822.49 345.83 1822.49 353.92V419.49H1803.31V305.487Z"
        fill={color}
      />
      <path
        d="M1918.6 302.933C1926.7 302.933 1934.55 304.849 1942.15 308.681C1949.75 312.442 1955.54 317.338 1959.51 323.37C1963.35 329.118 1965.91 335.824 1967.19 343.488C1968.32 348.739 1968.89 357.113 1968.89 368.609H1885.25C1885.61 380.176 1888.34 389.472 1893.46 396.498C1898.57 403.452 1906.49 406.929 1917.22 406.929C1927.23 406.929 1935.22 403.63 1941.19 397.03C1944.6 393.198 1947.01 388.763 1948.43 383.724H1967.29C1966.8 387.911 1965.13 392.595 1962.28 397.775C1959.51 402.885 1956.39 407.071 1952.91 410.336C1947.08 416.013 1939.87 419.845 1931.28 421.832C1926.66 422.967 1921.44 423.535 1915.62 423.535C1901.41 423.535 1889.37 418.39 1879.5 408.1C1869.63 397.74 1864.69 383.263 1864.69 364.671C1864.69 346.362 1869.66 331.495 1879.6 320.07C1889.55 308.645 1902.55 302.933 1918.6 302.933ZM1949.18 353.388C1948.4 345.085 1946.59 338.45 1943.75 333.482C1938.49 324.257 1929.72 319.644 1917.43 319.644C1908.62 319.644 1901.23 322.838 1895.27 329.225C1889.3 335.54 1886.14 343.595 1885.78 353.388H1949.18Z"
        fill={color}
      />
      <path
        d="M2000.33 363.819C2000.33 376.025 2002.92 386.244 2008.1 394.475C2013.29 402.707 2021.6 406.823 2033.04 406.823C2041.91 406.823 2049.2 403.026 2054.88 395.433C2060.63 387.769 2063.51 376.805 2063.51 362.542C2063.51 348.136 2060.56 337.492 2054.66 330.608C2048.77 323.654 2041.49 320.177 2032.82 320.177C2023.16 320.177 2015.31 323.867 2009.28 331.247C2003.31 338.627 2000.33 349.485 2000.33 363.819ZM2029.2 303.465C2037.94 303.465 2045.25 305.31 2051.15 309C2054.56 311.129 2058.43 314.854 2062.76 320.177V262.59H2081.19V419.49H2063.93V403.63C2059.46 410.655 2054.17 415.729 2048.06 418.851C2041.95 421.974 2034.95 423.535 2027.07 423.535C2014.35 423.535 2003.34 418.213 1994.04 407.568C1984.73 396.853 1980.08 382.624 1980.08 364.884C1980.08 348.278 1984.31 333.908 1992.76 321.773C2001.29 309.568 2013.43 303.465 2029.2 303.465Z"
        fill={color}
      />
      <path
        d="M2160.15 262.59H2178.8V319.325C2182.99 313.861 2188 309.71 2193.82 306.871C2199.65 303.962 2205.97 302.507 2212.79 302.507C2226.99 302.507 2238.5 307.403 2247.31 317.196C2256.19 326.918 2260.63 341.288 2260.63 360.306C2260.63 378.331 2256.26 393.304 2247.52 405.226C2238.79 417.148 2226.67 423.109 2211.19 423.109C2202.52 423.109 2195.21 421.016 2189.24 416.829C2185.69 414.345 2181.89 410.371 2177.84 404.907V419.49H2160.15V262.59ZM2210.02 406.184C2220.39 406.184 2228.13 402.068 2233.25 393.837C2238.43 385.605 2241.02 374.748 2241.02 361.265C2241.02 349.272 2238.43 339.337 2233.25 331.46C2228.13 323.583 2220.57 319.644 2210.55 319.644C2201.81 319.644 2194.14 322.873 2187.54 329.331C2181 335.789 2177.73 346.433 2177.73 361.265C2177.73 371.98 2179.08 380.673 2181.78 387.344C2186.83 399.904 2196.24 406.184 2210.02 406.184Z"
        fill={color}
      />
      <path
        d="M2350.02 305.487H2371.23C2368.53 312.796 2362.53 329.473 2353.22 355.516C2346.26 375.102 2340.43 391.069 2335.75 403.417C2324.67 432.512 2316.85 450.253 2312.31 456.639C2307.76 463.026 2299.95 466.219 2288.87 466.219C2286.17 466.219 2284.07 466.113 2282.58 465.9C2281.16 465.687 2279.38 465.297 2277.25 464.729V447.272C2280.59 448.195 2283.01 448.762 2284.5 448.975C2285.99 449.188 2287.3 449.295 2288.44 449.295C2291.99 449.295 2294.58 448.691 2296.22 447.485C2297.92 446.35 2299.34 444.93 2300.48 443.227C2300.84 442.66 2302.11 439.75 2304.32 434.499C2306.52 429.247 2308.12 425.344 2309.11 422.79L2266.92 305.487H2288.65L2319.23 398.307L2350.02 305.487Z"
        fill={color}
      />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d="M1003.68 494.237C1003.68 666.412 864.108 805.987 691.933 805.987C519.759 805.987 380.184 666.412 380.184 494.237C380.184 322.062 519.759 182.487 691.933 182.487C864.108 182.487 1003.68 322.062 1003.68 494.237ZM603.597 618.3H543.095C530.382 618.3 524.102 618.3 520.272 615.849C516.137 613.169 513.609 608.727 513.303 603.826C513.073 599.307 516.213 593.793 522.493 582.765L671.88 319.45C678.237 308.269 681.453 302.678 685.512 300.61C689.878 298.389 695.085 298.389 699.451 300.61C703.51 302.678 706.726 308.269 713.083 319.45L743.793 373.059L743.95 373.333C750.815 385.329 754.297 391.412 755.817 397.796C757.502 404.766 757.502 412.118 755.817 419.087C754.285 425.52 750.839 431.647 743.87 443.824L665.401 582.535L665.197 582.891C658.287 594.985 654.785 601.114 649.931 605.74C644.646 610.795 638.29 614.471 631.32 616.539C624.964 618.3 617.842 618.3 603.597 618.3H603.597ZM756.389 618.303H843.083C855.872 618.303 862.306 618.303 866.135 615.775C870.27 613.095 872.874 608.576 873.104 603.675C873.326 599.305 870.254 594.005 864.233 583.618C864.028 583.264 863.819 582.904 863.607 582.538L820.184 508.25L819.689 507.414C813.587 497.095 810.505 491.884 806.552 489.87C802.186 487.649 797.055 487.649 792.69 489.87C788.707 491.938 785.491 497.375 779.134 508.327L735.864 582.614L735.716 582.87C729.381 593.804 726.216 599.268 726.444 603.752C726.75 608.653 729.278 613.171 733.413 615.852C737.166 618.303 743.599 618.303 756.389 618.303Z"
        fill={color}
      />
    </svg>
  )
}
