import { useWeb3React } from '@web3-react/core'
import { Content, TwoColumn, LeftContainer, RightContainer } from '../components/common/Layout'
import { Mural } from '../components/Mural'
import { AuctionDetails } from '../components/AuctionDetails'
import { Leaderboard } from '../components/Leaderboard'
import { useAuction } from '../hooks/useAuction'
import { BigNumber } from 'ethers'
import { MuralDescription } from '../components/MuralDescription'
import { AuctionTimerWithMessage } from '../components/AuctionTimerWithMessage'
import { AuctionCTA } from '../components/AuctionCTA'
import { CurrentBid } from '../components/CurrentBid'
import { Footer } from '../components/Footer'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import styled from 'styled-components'
interface AuctionProps {
  contractAddress: string
  auctionInfo: ReturnType<typeof useAuction>
  afterEndTime: boolean | undefined
  afterClaimTime: boolean | undefined
  startingBid: BigNumber
  nextMinBid: BigNumber
  walletIsConnected: boolean
}

const TwoColumnOverride = styled(TwoColumn)`
  ${({ theme }) => theme.mediaWidth.upToSmall`    
    grid-template-columns: 50% 50%;
    grid-auto-flow: column;
  `};
`

export default function Auction({
  contractAddress,
  auctionInfo,
  afterEndTime,
  afterClaimTime,
  nextMinBid,
  startingBid,
  walletIsConnected,
}: AuctionProps) {
  const {
    auctionNFTAddress,
    auctionNFTId,
    auctionDuration,
    auctionStarted,
    auctionStartBlock,
    auctionStartTime,
    auctionEndTime,
    auctionClaimTime,
    auctionEnded,
    auctionHighestBid,
    auctionHighestBidder,
    auctionBidders,
    auctionLoading,
    isUsingGraph,
    walletCurrentBid,
    bid,
    withdraw,
    close,
    returnLosingBids,
    updateLosingBids,
    isWalletHighestBidder,
  } = auctionInfo
  const { account } = useWeb3React()
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()
  return (
    // padding={upToSmall ? '45px 0' : '45px 84px'}
    <Content
      align={'center'}
      margin={upToSmall ? '0 0 0' : upToMedium ? '0 0 60px' : '100px 0 0'}
      justify={'space-between'}
      minHeight={upToSmall ? 'calc(100vh - 82px)' : upToMedium ? 'calc(100vh - 84px)' : `calc(100vh - 84px)`}
    >
      <TwoColumn width={'100%'} align={'flex-start'} justify={'flex-start'} margin={'0 0 100px'}>
        <LeftContainer>
          <Mural />
          {upToMedium ? null : (
            <AuctionDetails
              contractAddress={contractAddress}
              duration={auctionDuration}
              startingBid={startingBid}
              started={auctionStarted}
              startBlock={auctionStartBlock}
              startTime={auctionStartTime}
              endTime={auctionEndTime}
              claimTime={auctionClaimTime}
              afterEndTime={afterEndTime}
            />
          )}
        </LeftContainer>
        <RightContainer>
          <MuralDescription address={auctionNFTAddress} id={auctionNFTId} />
          <TwoColumnOverride
            justify={'space-between'}
            margin={upToSmall ? '0 15px 28px' : upToMedium ? '0 30 60px' : '0 0 20px'}
          >
            <LeftContainer>
              <CurrentBid winAmount={auctionHighestBid} nextMinBid={nextMinBid} ended={auctionEnded}></CurrentBid>
            </LeftContainer>
            <RightContainer style={{ padding: '0' }}>
              <AuctionTimerWithMessage
                endTime={auctionEndTime}
                claimTime={auctionClaimTime}
                afterEndTime={afterEndTime}
                afterClaimTime={afterClaimTime}
                auctionStarted={auctionStarted}
                auctionEnded={auctionEnded}
              />
            </RightContainer>
          </TwoColumnOverride>
          <AuctionCTA
            walletIsConnected={walletIsConnected}
            afterClaimTime={afterClaimTime}
            nextMinBid={nextMinBid}
            walletCurrentBid={walletCurrentBid}
            auctionEnded={auctionEnded}
            endTime={auctionEndTime}
            auctionBidders={auctionBidders}
            winAmount={auctionHighestBid}
            isWalletHighestBidder={isWalletHighestBidder}
            bid={bid}
            withdraw={withdraw}
            close={close}
            returnLosingBids={returnLosingBids}
            updateLosingBids={updateLosingBids}
          />
          <Leaderboard
            bidders={auctionBidders}
            loading={auctionLoading}
            ended={auctionEnded}
            winner={auctionHighestBidder}
            winAmount={auctionHighestBid}
            account={account}
            isUsingGraph={isUsingGraph}
          />
          {upToMedium ? (
            <AuctionDetails
              contractAddress={contractAddress}
              duration={auctionDuration}
              startingBid={startingBid}
              started={auctionStarted}
              startBlock={auctionStartBlock}
              startTime={auctionStartTime}
              endTime={auctionEndTime}
              claimTime={auctionClaimTime}
              afterEndTime={afterEndTime}
            />
          ) : null}
        </RightContainer>
      </TwoColumn>
      <Footer />
    </Content>
  )
}
