import styled, { useTheme } from 'styled-components'
import { CaretIcon } from '../icons/Caret'
import { HF } from './Layout'

interface ArrowProps {
  size?: string
  direction?: ArrowDirection
  handleClick: () => void
}

const Container = styled(HF)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(247, 247, 247, 1);
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  z-index: 10;

  top: 0;
  bottom: 0;

  &:hover {
    background-color: rgba(247, 247, 247, 0.6);
  }

  &:active,
  &:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.6);
  }
`

export enum ArrowDirection {
  LEFT,
  RIGHT,
}

export function Arrow({ size = '48px', direction, handleClick }: ArrowProps) {
  const theme = useTheme()
  return (
    <Container onClick={handleClick} style={{ width: size, height: size }}>
      <CaretIcon color={theme.colors.text} rotate={direction === ArrowDirection.LEFT ? '0deg' : '180deg'} />
    </Container>
  )
}
