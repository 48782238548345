import logo from '../assets/logo.png'
import { abbreviateAddress } from '../utils/abbreviateAddress'
import { connectOrDisconnect } from '../services/connectOrDisconnect'
import styled from 'styled-components'
import { HF } from '../components/common/Layout'
import { useWeb3React } from '@web3-react/core'
import { ForwardedRef, forwardRef } from 'react'
import { Button1, Button2 } from './common/Button'
import { NavbarLink } from '../components/common/Link'
import { NavLink } from 'react-router-dom'
import { BigNumber, ethers } from 'ethers'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'

export type NavbarProps = {
  disconnect: () => void
  className?: string
  balance: BigNumber | undefined
  nextMinBid: BigNumber
  packPrice: BigNumber
  walletCurrentBid: BigNumber
}

const HeaderContainer = styled(HF)`
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  z-index: 100;
`

const HeaderContent = styled(HF)`
  padding: 22px 0;
  width: 90%;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    padding: 16px 15px;
    width: 100%;
  `};
`

export const HeaderLogo = styled.img`
  height: 20px;
  margin: 0 48px 0 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 17px;
    margin: 0 0 24px 0;
  `};
`
export const Links = styled.div`
  * {
    margin-right: 24px;
  }
`

const ButtonLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ButtonBalance = styled(Button2)`
  border-top-right-radius: 0;
  border-bottom-right-radius 0;
  min-width: unset;
  border: none;

  &[disabled] {
    background-color: transparent !important;
    border: none;    
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }  
`
const BalanceText = styled.span`
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 12px !important;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;
  `};
`

function NavbarInner(
  { disconnect, className, balance, nextMinBid, packPrice, walletCurrentBid }: NavbarProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { active, account, deactivate, activate, setError } = useWeb3React()
  const { upToExtraSmall } = useGetIsMobileScreenSize()

  const responsivePadding = upToExtraSmall ? '4px 12px 6px' : '8px 24px 10px'
  const responsiveHeight = upToExtraSmall ? '34px' : '40px'
  const responsiveMinWidth = upToExtraSmall ? 'unset' : '114px'
  const responsiveFontSize = upToExtraSmall ? '14px' : '16px'

  const minBalance =
    window.location.pathname === '/'
      ? nextMinBid.sub(walletCurrentBid)
      : '/auction'
      ? nextMinBid.sub(walletCurrentBid)
      : packPrice

  return (
    <HeaderContainer ref={ref} className={className}>
      <HeaderContent>
        <NavLink to="/">
          <HeaderLogo src={logo} />
        </NavLink>
        <HF align={'center'} justify={upToExtraSmall ? 'space-between' : 'flex-end'} width="100%">
          <Links>
            <NavbarLink
              fontSize={upToExtraSmall ? '12px' : '16px'}
              to={{ pathname: 'https://support.avax.network/en/collections/3165315-jason-peterson-auction-faq-s' }}
              target="_blank"
            >
              FAQ
            </NavbarLink>
          </Links>
          {active ? (
            <ButtonContainer>
              <ButtonBalance
                height={responsiveHeight}
                padding={responsivePadding}
                minWidth={responsiveMinWidth}
                fontSize={responsiveFontSize}
                disabled
              >
                {balance ? (
                  balance.gt(minBalance) ? (
                    <BalanceText>
                      <span>{Number(ethers.utils.formatEther(balance)).toFixed(1)}</span>
                      <span>AVAX</span>
                    </BalanceText>
                  ) : (
                    <ButtonLink href="https://www.coinbase.com/price/avalanche" target="_blank">
                      Get AVAX
                    </ButtonLink>
                  )
                ) : (
                  ''
                )}
              </ButtonBalance>
              <Button2
                height={responsiveHeight}
                padding={responsivePadding}
                minWidth={responsiveMinWidth}
                fontSize={responsiveFontSize}
                onClick={() => connectOrDisconnect({ active, account, deactivate, activate, setError }, disconnect)}
              >
                {account ? abbreviateAddress(account, 3) : 'DISCONNECT'}
              </Button2>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <ButtonBalance
                height={responsiveHeight}
                padding={responsivePadding}
                minWidth={responsiveMinWidth}
                fontSize={responsiveFontSize}
                disabled
              >
                <ButtonLink href="https://www.coinbase.com/price/avalanche" target="_blank">
                  Get AVAX
                </ButtonLink>
              </ButtonBalance>
              <Button1
                height={responsiveHeight}
                padding={responsivePadding}
                minWidth={responsiveMinWidth}
                fontSize={responsiveFontSize}
                onClick={() => connectOrDisconnect({ active, account, deactivate, activate, setError }, disconnect)}
              >
                <span style={{ paddingBottom: upToExtraSmall ? 0 : 2 }}>Connect</span>
              </Button1>
            </ButtonContainer>
          )}
        </HF>
      </HeaderContent>
    </HeaderContainer>
  )
}

export const Navbar = forwardRef(NavbarInner)
