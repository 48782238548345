import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { WrongNetwork } from '../error/WrongNetwork'
import { BasicError } from '../error/Basic'
import { SubTextTypography, Typography } from '../common/Typography'
import { VF } from '../common/Layout'

export default function Web3ReactManager({
  allowInactiveNetwork,
  children,
}: {
  allowInactiveNetwork: boolean
  children: JSX.Element
}) {
  const { active: networkActive, error: networkError } = useWeb3React()

  // on page load, do nothing until we've tried to connect to the injected connector
  if (networkError && networkError instanceof UnsupportedChainIdError) {
    return <WrongNetwork></WrongNetwork>
  } else if (networkError) {
    return (
      <BasicError>
        <VF align={'center'} justify={'center'}>
          <Typography>Network error</Typography>
          <SubTextTypography margin={'10px 0 0 0'}>
            {networkError || 'Unknown error check the console for more information'}
          </SubTextTypography>
        </VF>
      </BasicError>
    )
  } else if (!networkActive && !allowInactiveNetwork) {
    return <Typography>No network detected</Typography>
  }

  return children
}
