import { useTimer } from 'react-timer-hook'
import styled from 'styled-components'
import { HF, VF } from './common/Layout'
import { Typography } from './common/Typography'

const TimerModule = styled.div``

const Timer = styled(Typography)`
  display: flex;
  align-item: center;
  justify-content: center;
  flex-direction: column;

  width: 28px;
  padding: 2px 4px;

  background: #393939;
  border-radius: 8px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
`

const TimerSeperator = styled(Timer)`
  width: 10px;
  padding: 2px 2px;
  color: black;
  background: transparent;
`

export function AuctionTimer({ endTime }: { endTime: Date }) {
  const { days, hours, minutes, seconds } = useTimer({ expiryTimestamp: endTime.getTime() })
  return (
    <HF justify="center">
      <HF align={'center'}>
        <VF align={'center'}>
          <TimerModule>
            <Timer>{days < 10 ? '0' + days : days}</Timer>
          </TimerModule>
        </VF>
        <TimerSeperator>:</TimerSeperator>
        <VF align={'center'}>
          <TimerModule>
            <Timer>{hours < 10 ? '0' + hours : hours}</Timer>
          </TimerModule>
        </VF>
        <TimerSeperator>:</TimerSeperator>
        <VF align={'center'}>
          <TimerModule>
            <Timer>{minutes < 10 ? '0' + minutes : minutes}</Timer>
          </TimerModule>
        </VF>
        <TimerSeperator>:</TimerSeperator>
        <VF align={'center'}>
          <TimerModule>
            <Timer>{seconds < 10 ? '0' + seconds : seconds}</Timer>
          </TimerModule>
        </VF>
      </HF>
    </HF>
  )
}
