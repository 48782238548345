import Swal from 'sweetalert2'
import {
  confirmationNotification,
  successNotification,
  errorNotification,
  processingNotification,
} from './notifications'

export async function executeClose(func: () => Promise<any>) {
  try {
    const { isConfirmed } = await confirmationNotification({
      title: 'Close the Auction',
      text: `The claim time has elapsed. This action will close the auction and transfer the NFT to the highest bidder.`,
      confirmButtonText: 'Close the Auction',
    })

    if (isConfirmed) {
      processingNotification({})
      const tx = await func()
      await tx.wait(1)
      await successNotification({ text: 'You successfully closed the auction' })
    }
  } catch (e: any) {
    console.log(e)
    if (e.message && e.data) {
      await errorNotification({ title: e.message, text: e.data.message })
    }
  } finally {
    Swal.close()
  }
}
