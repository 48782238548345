import { Link } from 'react-router-dom'
import { HF, VF } from './common/Layout'
import { CurrentBid } from './CurrentBid'
import styled from 'styled-components'
import { TitleBig, TypographyColor } from './common/Typography'
import { BigNumber } from 'ethers'
import { Button1 } from './common/Button'
import background from '../assets/4153079N8739049W-sm.jpg'
import { Shadow } from './Shadow'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import { AuctionTimer } from './AuctionTimer'

interface AuctionHeroProps {
  ended: boolean | undefined
  endTime: Date | undefined
  afterClaimTime: boolean | undefined
  nextMinBid: BigNumber
  winAmount: BigNumber
}

const Card = styled(HF)`
  min-height: 712px;

  background: url(${background}) linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;

  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-height: 545px;
  `};
`
const Content = styled(VF)`
  z-index: 10;
`

export function AuctionHero({ endTime, afterClaimTime, nextMinBid, winAmount, ended }: AuctionHeroProps) {
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()

  return (
    <Card
      width={'100%'}
      margin={upToSmall ? '0 0 0' : upToMedium ? '0 0 150px' : '0 0 150px'}
      padding={upToSmall ? '0 15px 100px' : upToMedium ? '0 30px 60px' : '30px 60px'}
      style={{ backgroundImage: `url(${background})` }}
    >
      <Content width={'100%'} align={'flex-start'} justify={'flex-end'}>
        <TitleBig color={TypographyColor.WHITE}>{afterClaimTime ? 'Auction' : 'Live Auction'}</TitleBig>
        {endTime ? (
          <HF margin={'16px 0'}>
            <AuctionTimer endTime={endTime} />
          </HF>
        ) : null}
        <CurrentBid
          winAmount={winAmount}
          nextMinBid={nextMinBid}
          ended={ended}
          color={TypographyColor.WHITE}
        ></CurrentBid>
        <Link to={`/auction`} style={{ textDecoration: 'none' }}>
          <Button1 margin={'24px 0'}>Bid</Button1>
        </Link>
      </Content>
      <Shadow />
    </Card>
  )
}
