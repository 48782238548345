import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useEffect, useState } from 'react'
import packsABI from '../abi/packs.abi.json'
import NFTABI from '../abi/nft.abi.json'
import config from '../runtime/config'

export function usePack(address: string, walletIsConnected: boolean) {
  const [maxPacks, setMaxPacks] = useState<BigNumber>()
  const [price, setPrice] = useState<BigNumber>()
  const [packsMinted, setPacksMinted] = useState<BigNumber>()
  const [NFTAddresses, setNFTAddresses] = useState<string[]>()
  const [tokenURIs, settokenURIs] = useState<string[]>()
  const [tokenMetadata, setTokenMetadata] = useState<any[]>([])
  const { library } = useWeb3React() // AVALANCHE RPC
  const defaultProvider = ethers.getDefaultProvider(config.app.networkUrl)

  useEffect(
    () => {
      // ABI
      const packsContract = library
        ? new ethers.Contract(address, packsABI, library)
        : new ethers.Contract(address, packsABI, defaultProvider)

      Promise.all([
        // get constants
        packsContract.maxPacks(),
        packsContract.price(),
        packsContract.getNFTAddresses(),
        // get variables
        packsContract.packsMinted(),
      ]).then(async (data) => {
        setMaxPacks(data[0])
        setPrice(data[1])
        setNFTAddresses(data[2].map((d: string) => d.toLowerCase()))
        // console.log('NFTAddresses', NFTAddresses)
        if (NFTAddresses) {
          settokenURIs(await gettokenURIs(NFTAddresses))
          // console.log('TOKENURIS', tokenURIs)
          let metadata = await getTokenMetadata(tokenURIs)
          // console.log('METADATA', metadata)
          setTokenMetadata(metadata)
        }
        setPacksMinted(data[3])
      })

      // SUBSCRIBE TO PACKS EVENTS
      packsContract.on(
        {
          address,
          topics: [],
        },
        (res) => {
          switch (res.event) {
            case 'PacksBought':
              setPacksMinted(res.args.totalPacksMinted.toString())
              break
          }
        }
      )

      return () => {
        packsContract.removeAllListeners()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [address, library]
  )

  async function buy(packsBought: number) {
    if (!walletIsConnected || !library || !price) {
      return
    }

    const overrides = {
      value: price.mul(packsBought),
    }

    return await getContractWithSigner().buy(packsBought, overrides)
  }

  function getContractWithSigner() {
    const signer = library.getSigner()
    const packsContract = new ethers.Contract(address, packsABI, signer)
    return packsContract
  }

  async function gettokenURIs(NFTAddresses: any) {
    console.log('   NFTAddresses', NFTAddresses)
    let uris = []
    if (NFTAddresses) {
      for (const address of NFTAddresses) {
        let nftContract = library
          ? new ethers.Contract(address, NFTABI, library)
          : new ethers.Contract(address, NFTABI, defaultProvider)
        let uri = (await nftContract.baseURI()).split('ipfs://')[1]
        uris.push(uri)
      }
    }
    return uris
  }

  async function getTokenMetadata(URIs: string[] | undefined) {
    if (!URIs) {
      return []
    }
    let metadata = []
    console.log('       uris', URIs)
    for (const uri of URIs) {
      const res = await fetch(`https://ipfs.io/ipfs/${uri}`)
      const metadatum = await res.json()
      const imageURI = metadatum.image.split('ipfs://')[1]
      metadatum.image = `https://ipfs.io/ipfs/${imageURI}`
      metadata.push(metadatum)
    }
    return metadata
  }

  return {
    maxPacks,
    packsMinted,
    price,
    NFTAddresses,
    tokenURIs,
    tokenMetadata,
    buy,
  }
}
