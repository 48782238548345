import { BigNumber } from 'ethers'
import Swal, { SweetAlertOptions } from 'sweetalert2'
import loadingGif from '../assets/loading.svg'
import { twitterIntentBid } from '../utils/twitterIntent'
import config from '../runtime/config'

const WIDTH_LG = '623px'

export interface NotificationOptions extends SweetAlertOptions {
  bid?: BigNumber
  endTime?: Date | undefined
  address?: any
}

export function closeAllNotifications() {
  Swal.close()
}

export function confirmationNotification({
  title,
  text,
  confirmButtonText = 'OK',
  width = WIDTH_LG,
}: NotificationOptions) {
  return Swal.fire({
    title,
    text,
    showConfirmButton: true,
    confirmButtonText,
    width,
  })
}

export function processingNotification({
  title = 'Processing...',
  text = 'Please wait while your transaction is processed',
  width = WIDTH_LG,
}: NotificationOptions) {
  return Swal.fire({
    title,
    text,
    iconHtml: `<img class="loading-spinner" src='${loadingGif}'/>`,
    iconColor: '#fff',
    showConfirmButton: false,
    allowOutsideClick: false,
    width,
  })
}

export function successNotification({ text, width = WIDTH_LG }: NotificationOptions) {
  return Swal.fire({
    title: 'Success',
    text,
    icon: 'success',
    iconColor: '#fff',
    width,
    confirmButtonText: 'Done',
  })
}

export function successNotificationWithTweet({ text, width = WIDTH_LG, bid, endTime }: NotificationOptions) {
  return Swal.fire({
    title: 'Success',
    text,
    icon: 'success',
    iconColor: '#fff',
    width,
    showCancelButton: true,
    confirmButtonText: 'Done',
    cancelButtonText: `<svg height="14px" viewBox="328 355 335 276" xmlns="http://www.w3.org/2000/svg" class="twitter-icon-bid">
    <path d="
      M 630, 425
      A 195, 195 0 0 1 331, 600
      A 142, 142 0 0 0 428, 570
      A  70,  70 0 0 1 370, 523
      A  70,  70 0 0 0 401, 521
      A  70,  70 0 0 1 344, 455
      A  70,  70 0 0 0 372, 460
      A  70,  70 0 0 1 354, 370
      A 195, 195 0 0 0 495, 442
      A  67,  67 0 0 1 611, 380
      A 117, 117 0 0 0 654, 363
      A  65,  65 0 0 1 623, 401
      A 117, 117 0 0 0 662, 390
      A  65,  65 0 0 1 630, 425
      Z"
      style="fill:#ffffff;"/>
  </svg> Share`,
  }).then((result) => {
    if (result.isConfirmed) {
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      if (bid) {
        window.location.href = twitterIntentBid(bid, endTime)
      }
    }
  })
}

export function successNotificationWithExplorer({ text, width = WIDTH_LG, address }: NotificationOptions) {
  return Swal.fire({
    title: 'Success',
    text,
    icon: 'success',
    iconColor: '#fff',
    width,
    showCancelButton: false,
    confirmButtonText: 'View NFTs',
  }).then((result) => {
    if (result.isConfirmed) {
      window.open(`${config.app.explorerUrl}${process.env.REACT_APP_ADDRESS_BASE_URL}${address}`, '_blank')
    }
  })
}

export function errorNotification({ title, text, width = WIDTH_LG }: NotificationOptions) {
  return Swal.fire({
    title,
    text,
    icon: 'warning',
    iconColor: '#fff',
    width,
  })
}

export function errorNotificationWithMetamask({ title, text, width = WIDTH_LG }: NotificationOptions) {
  return Swal.fire({
    title,
    text,
    icon: 'warning',
    iconColor: '#fff',
    width,
    showCancelButton: true,
    confirmButtonText: 'OK',
    cancelButtonText: `What's Metamask?`,
  }).then((result) => {
    if (result.dismiss === Swal.DismissReason.cancel) {
      window.location.href = 'https://support.avax.network/en/articles/4626956-how-do-i-set-up-metamask-on-avalanche'
    }
  })
}
