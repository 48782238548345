const mainnet: any = {
  artist: {
    '0xa2c63eeeF662f71427aBF234E7CC3D6b8BbfaF57': {
      artist: 'Jason M Peterson',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmWnGCgUYmuNKAeHBpTEzEz13KAcWpicr4XvruH7o3qoYq/A%20Boat.jpg',
      name: 'A Boat',
    },
    '0x87e90C292C135f5593d536E1caa69ab2A32C7e86': {
      artist: 'Jason M Peterson',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmWnGCgUYmuNKAeHBpTEzEz13KAcWpicr4XvruH7o3qoYq/Death%20do%20us%20part.jpg',
      name: 'Death Do Us Part',
    },
    '0x9649371DC14E2bccfa8ac70603137147fB8CDBab': {
      artist: 'Jason M Peterson',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmWnGCgUYmuNKAeHBpTEzEz13KAcWpicr4XvruH7o3qoYq/Die%20lit.jpg',
      name: 'Die Lit',
    },
    '0x33d8948439De0ca4C3431535C256c20D5f8c8Ccf': {
      artist: 'Jason M Peterson',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmWnGCgUYmuNKAeHBpTEzEz13KAcWpicr4XvruH7o3qoYq/Kids%20see%20ghosts.jpg',
      name: 'Kids See Ghosts',
    },
    '0xB6fFcb63eDd1e8E41210d1184d2e35A550a9CCdd': {
      artist: 'Jason M Peterson',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmWnGCgUYmuNKAeHBpTEzEz13KAcWpicr4XvruH7o3qoYq/Pretty%20Flaco.jpg',
      name: 'Pretty Flaco',
    },
  },
  art: {
    '0x5407091A54AC390DA91B229716dF8a18dF3c1503': {
      artist: 'Jason M Peterson',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmQPgXCxr8jA25us1WXS2nFpkFBYGG8nFQEBMNL3EcWnba/1A.jpg',
      name: '1A',
    },
    '0xc9F4175D61B799128298e9f3Ed089406D9635389': {
      artist: 'Jason M Peterson',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmQPgXCxr8jA25us1WXS2nFpkFBYGG8nFQEBMNL3EcWnba/Remember%20september.jpg',
      name: 'Remember September',
    },
    '0x11957Bf85C5F2Dd92a45ce5eCf71d1Eae1245BF7': {
      artist: 'Jason M Peterson',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmQPgXCxr8jA25us1WXS2nFpkFBYGG8nFQEBMNL3EcWnba/Strangeways%20here%20we%20come.jpg',
      name: 'Strangeways here we come',
    },
    '0xE31442699BC8c04FdcaC9dAA8849Af516F60853D': {
      artist: 'Jason M Peterson',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmQPgXCxr8jA25us1WXS2nFpkFBYGG8nFQEBMNL3EcWnba/Upside%20down.jpg',
      name: 'Upside down',
    },
    '0x16892F1aE2c807f2607a6c51B991b4E352214396': {
      artist: 'Jason M Peterson',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmQPgXCxr8jA25us1WXS2nFpkFBYGG8nFQEBMNL3EcWnba/Violet%20hour.jpg',
      name: 'Violet Hour',
    },
  },
}

const testnet: any = {
  artist: {
    '0xedb1ae5e44cba6296eeb4d62ef0e5b223f93176f': {
      artist: 'Jason M Peterson',
      description: 'A boat description',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmWnGCgUYmuNKAeHBpTEzEz13KAcWpicr4XvruH7o3qoYq/A%20Boat.jpg',
      name: 'A Boat',
    },
    '0x288f185f404a60f042ccc9c16eca6f82206ab536': {
      artist: 'Jason M Peterson',
      description: 'Death do us part description',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmWnGCgUYmuNKAeHBpTEzEz13KAcWpicr4XvruH7o3qoYq/Death%20do%20us%20part.jpg',
      name: 'Death do us part',
    },
    '0x809524a594c1babea4df50b6ba5fd021b108ae22': {
      artist: 'Jason M Peterson',
      description: 'Die lit description',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmWnGCgUYmuNKAeHBpTEzEz13KAcWpicr4XvruH7o3qoYq/Die%20lit.jpg',
      name: 'Die lit',
    },
    '0x0b9b531d3c329acfbdc54b3c8757a600d7cbe27f': {
      artist: 'Jason M Peterson',
      description: 'Kids see ghosts description',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmWnGCgUYmuNKAeHBpTEzEz13KAcWpicr4XvruH7o3qoYq/Kids%20see%20ghosts.jpg',
      name: 'Kids see ghosts',
    },
    '0x58ca0e9fadb9795a7fecdc253be1350ba01d49a8': {
      artist: 'Jason M Peterson',
      description: 'Pretty flaco description',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmWnGCgUYmuNKAeHBpTEzEz13KAcWpicr4XvruH7o3qoYq/Pretty%20Flaco.jpg',
      name: 'Pretty flaco',
    },
  },
  art: {
    '0x3d22d0a81f65c4eff9e71e8df60bcf050116f63c': {
      artist: 'Jason M Peterson',
      description: '1a description',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmQPgXCxr8jA25us1WXS2nFpkFBYGG8nFQEBMNL3EcWnba/1A.jpg',
      name: '1a',
    },
    '0x38745c3988390ab08359db63b87e39ceb5234f34': {
      artist: 'Jason M Peterson',
      description: 'Remember september description',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmQPgXCxr8jA25us1WXS2nFpkFBYGG8nFQEBMNL3EcWnba/Remember%20september.jpg',
      name: 'Remember september',
    },
    '0x9437a468a58a8e04c1bb51f8243f28a710eb262b': {
      artist: 'Jason M Peterson',
      description: 'Strangeways here we come description',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmQPgXCxr8jA25us1WXS2nFpkFBYGG8nFQEBMNL3EcWnba/Strangeways%20here%20we%20come.jpg',
      name: 'Strangeways here we come',
    },
    '0x1fe79fd6ecfcb91aa9ceb2fffa069c820dd534d7': {
      artist: 'Jason M Peterson',
      description: 'Upside down description',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmQPgXCxr8jA25us1WXS2nFpkFBYGG8nFQEBMNL3EcWnba/Upside%20down.jpg',
      name: 'Upside down',
    },
    '0x1ccfa12ac82f911eb1eba44f10f6dbec4dd528df': {
      artist: 'Jason M Peterson',
      description: 'Violet hour description',
      external_url: 'https://yeahprobablynothing.com',
      image: 'https://ipfs.io/ipfs/QmQPgXCxr8jA25us1WXS2nFpkFBYGG8nFQEBMNL3EcWnba/Violet%20hour.jpg',
      name: 'Violet Hour',
    },
  },
}

export const packMetadata = process.env.REACT_APP_NETWORKID === '1' ? mainnet : testnet
