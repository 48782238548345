import { RuntimeConfig } from '../@types/config'
import { TokenConfig } from '../@types/tokens'
import { MAINNET_TOKENS, TESTNET_TOKENS } from './tokens'
import { MAINNET_APP_CONFIG, TESTNET_APP_CONFIG } from './app'

export const prod: RuntimeConfig = {
  app: MAINNET_APP_CONFIG,
  tokens: MAINNET_TOKENS,
  tokenList(): TokenConfig[] {
    return Object.values(MAINNET_TOKENS)
  },
  decimalPrecision: 4,
}

export const dev: RuntimeConfig = {
  app: TESTNET_APP_CONFIG,
  tokens: TESTNET_TOKENS,
  tokenList(): TokenConfig[] {
    return Object.values(TESTNET_TOKENS)
  },
  decimalPrecision: 4,
}

const config = process.env.REACT_APP_NETWORKID === '1' ? prod : dev

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // Add common runtime values here
  ...config,
}
