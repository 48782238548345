import Swal from 'sweetalert2'
import { BigNumber, ethers } from 'ethers'
import config from '../runtime/config'
import {
  confirmationNotification,
  successNotification,
  errorNotification,
  processingNotification,
} from './notifications'

export async function executeWithdraw(
  walletCurrentBid: BigNumber,
  isWalletHighestBidder: () => boolean | undefined,
  func: () => Promise<any>
) {
  try {
    if (isWalletHighestBidder()) {
      await confirmationNotification({
        title: 'Cannot Withdraw',
        text: `You are the highest bidder. The highest bidder cannot withdraw their bid.`,
      })
    } else {
      const { isConfirmed } = await confirmationNotification({
        title: 'Withdraw your bid',
        text: `This will withdraw ${Number(ethers.utils.formatUnits(walletCurrentBid)).toFixed(
          config.decimalPrecision
        )} AVAX from the auction.`,
        confirmButtonText: 'Withdraw',
      })

      if (isConfirmed === true) {
        processingNotification({})
        const tx = await func()
        await tx.wait(1)
        await successNotification({ text: 'Your bid was successfully withdrawn' })
      }
    }
  } catch (e: any) {
    console.log(e)
    if (e.message && e.data) {
      await errorNotification({ title: e.message, text: e.data.message })
    }
  } finally {
    Swal.close()
  }
}
