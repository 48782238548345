import { createContext, useContext, Context, useState } from 'react'
import { ContractTokens, TokenConfig } from '../@types/tokens'
import config from '../runtime/config'

export const defaultProviderValue = {}

let applicationContext: Context<{
  contracts?: TokenConfig[]
  tokens?: ContractTokens
}>

export function ApplicationContextProvider({ children }: { children: any }) {
  const [contracts] = useState<TokenConfig[]>(Object.values(config.tokens))

  applicationContext = createContext({})
  const Provider = applicationContext.Provider
  return <Provider value={{ contracts, tokens: config.tokens }}>{children}</Provider>
}

export function useApplicationContext() {
  return useContext(applicationContext)
}
