import { useWeb3React } from '@web3-react/core'
import { usePack } from '../hooks/usePack'
import { Button1 } from '../components/common/Button'
import { HF, VF, Content, TwoColumn, LeftContainer, RightContainer } from '../components/common/Layout'
import { Title2, Label, Label2, Typography } from '../components/common/Typography'
import styled from 'styled-components'
import { BigNumber } from 'ethers'
import { Carousel } from '../components/Carousel'
import { DisplayPrice } from '../components/common/DisplayPrice'
import { PackCount } from '../components/PackCount'
import { Footer } from '../components/Footer'
import { executeBuy } from '../services/executeBuy'
import { getPackCount } from '../utils/getPackCount'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import { abbreviateAddress } from '../utils/abbreviateAddress'

const Textbody = styled(VF)`
  margin-top: 10px;
`

const USD = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  color: #6c6c6e;
`

export type PackProps = {
  walletIsConnected: boolean | undefined
  contractAddress: string
  packInfo: ReturnType<typeof usePack>
  balance: BigNumber | undefined
}

export default function Pack({ walletIsConnected, contractAddress, packInfo, balance }: PackProps) {
  const { buy, maxPacks, packsMinted, price } = packInfo
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()

  const { account } = useWeb3React()
  const packCount = getPackCount(packsMinted, maxPacks)
  const id = window.location.pathname.split('/')[2]
  const title = id.charAt(0).toUpperCase() + id.slice(1) + ' Pack'
  const soldOut = packCount !== "-" && parseInt(packCount) <= 0

  return (
    <Content
      align={'center'}
      margin={upToSmall ? '0 0 0' : upToMedium ? '0 0 60px' : '100px 0 0px'}
      justify={'space-between'}
      minHeight={`calc(100vh - 84px)`}
    >
      <TwoColumn width={'100%'} align={'flex-start'} justify={'flex-start'} margin={'0 0 100px'}>
        <LeftContainer justify={'flex-start'}>
          <Carousel id={id} />
        </LeftContainer>

        <RightContainer justify={'flex-start'}>
          <HF margin={'0 0 20px'} padding={upToSmall ? '0 15px' : upToMedium ? '0 30px' : '0'}>
            <Title2>{title}</Title2>
          </HF>
          <HF margin={'0 0 20px'} padding={upToSmall ? '0 15px' : upToMedium ? '0 30px' : '0'}>
            <Textbody>
              <Typography margin={'10px 0 0'}>
                <p>
                  Yeah Probably Nothing NFT packs are being presented and priced for collectors as an introduction to
                  Jason Peterson's work.
                </p>
                {id === 'artist' ? (
                  <p>
                    The artist pack is a collection of 5 NFTs that represent some of his favorite images he's shot of
                    musicians and celebrities.
                  </p>
                ) : (
                  <p>The art pack is a collection of 5 NFTs that represent some of his favorite images.</p>
                )}
              </Typography>
            </Textbody>
          </HF>
          <VF margin={'0 0 20px'} padding={upToSmall ? '0 15px' : upToMedium ? '0 30px' : '0'}>
            <Label2>Contract</Label2>
            <Typography>{upToSmall ? abbreviateAddress(contractAddress, 4) : contractAddress}</Typography>
          </VF>

          <HF
            justify={'space-between'}
            margin={'0 0 20px'}
            padding={upToSmall ? '0 15px' : upToMedium ? '0 30px' : '0'}
          >
            <VF margin={'10px 0 0'}>
              <Label>Price</Label>
              <HF align={'center'}>
                {price ? <DisplayPrice price={price} /> : '-'}
                <USD></USD>
              </HF>
            </VF>
            <VF margin={'10px 0 0'}>
              <Label>Limited Edition</Label>
              {maxPacks ? <PackCount packsLeft={packCount} maxPacks={maxPacks.toString()} /> : '-'}
            </VF>
          </HF>
          <HF margin={'0 0 20px'} padding={upToSmall ? '0 15px' : upToMedium ? '0 30px' : '0'}>
            {!soldOut && (
              <Button1 disabled={!walletIsConnected || !price} onClick={() => executeBuy(price, account, balance, buy)}>
                Buy Pack
              </Button1>
            )}
          </HF>
        </RightContainer>
      </TwoColumn>
      <Footer />
    </Content>
  )
}
