import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import auctionABI from '../abi/auction.abi.json'
import { BigNumber, ethers } from 'ethers'
import { gql, useQuery } from '@apollo/client'
import { biddersQuery } from '../graphql/queries'
import config from '../runtime/config'
export interface BidderResponse {
  exists: boolean
  bids: BigNumber
}
export interface Bidder {
  address: string
  value: BigNumber | null | undefined
  time?: number
  withdrawn?: boolean
}

export function useAuction(address: string) {
  const { library, account } = useWeb3React() // AVALANCHE RPC
  const { data, refetch } = useQuery(gql(biddersQuery)) // INDEXED BIDDERS DATA

  // AUCTION
  // constants
  const [auctionNFTAddress, setNFTAddress] = useState<string>()
  const [auctionNFTId, setNFTId] = useState<string>()
  const [auctionSeller, setSeller] = useState<string>()
  const [auctionStartingPrice, setStartingPrice] = useState<BigNumber>(BigNumber.from(0))
  const [auctionMinBidIncrement, setMinBidIncrement] = useState<BigNumber>(BigNumber.from(0))
  const [auctionDuration, setDuration] = useState<number>()
  const [auctionStarted, setStarted] = useState<boolean>()
  const [auctionStartBlock, setStartBlock] = useState<number>()
  const [auctionStartTime, setStartTime] = useState<Date>()
  // variables
  const [auctionEndTime, setEndTime] = useState<Date>()
  const [auctionClaimTime, setClaimTime] = useState<Date>()
  const [auctionEnded, setEnded] = useState<boolean>()
  const [auctionHighestBid, setHighestBid] = useState<BigNumber>(BigNumber.from(0))
  const [auctionHighestBidder, setHighestBidder] = useState<string>('')
  const [auctionBidders, setAuctionBidders] = useState<Bidder[]>([])
  // loading
  const [auctionLoading, setLoading] = useState<boolean>(true)

  // WALLET
  const [walletIsConnected, setIsConnected] = useState(false) // useACtive from web3
  const [walletCurrentBid, setCurrentBid] = useState<BigNumber>(BigNumber.from(0))
  const [walletBalance, setBalance] = useState<BigNumber>()

  // INDEXER
  const [isUsingGraph, setIsUsingGraph] = useState(false)
  const defaultProvider = ethers.getDefaultProvider(config.app.networkUrl)

  // INIT AND WATCH AUCTION
  useEffect(
    () => {
      // ABI
      const auctionContract = library
        ? new ethers.Contract(address, auctionABI, library)
        : new ethers.Contract(address, auctionABI, defaultProvider) // use https://github.com/ava-labs/avalanche-bridge/blob/develop/src/runtime/avalancheProvider.ts#L8-L9

      // ENRICH BIDDERS WITH HISTORIC INDEX
      const useGraph = true // dev tooling
      let biddersIndexed: Bidder[] | undefined
      //@ts-ignore
      if (data) {
        const biddersMap: Map<string, Bidder> = new Map()
        data.bidderEntities.forEach((element: any) => {
          let bidder: Bidder = {
            address: element.bidder,
            value: BigNumber.from(element.amount),
            time: parseInt(element.bidtime) * 1000,
            withdrawn: element.withdrawn,
          }
          biddersMap.set(bidder.address, bidder)
        })
        biddersIndexed = Array.from(biddersMap.values())
      }

      // GET STATE OF AUCTION
      Promise.all([
        // get constants
        auctionContract.NFTAddress(),
        auctionContract.NFTId(),
        auctionContract.seller(),
        auctionContract.startingPrice(),
        auctionContract.minimumIncrement(),
        auctionContract.duration(),
        auctionContract.started(),
        auctionContract.startBlock(),
        // get variables
        auctionContract.endTime(),
        auctionContract.claimTime(),
        auctionContract.ended(),
        auctionContract.highestBid(),
        auctionContract.highestBidder(),
        auctionContract.getBiddersList(),
      ]).then(async (data) => {
        // set constants
        setNFTAddress(data[0])
        setNFTId(data[1].toString())
        setSeller(data[2])
        setStartingPrice(data[3])
        setMinBidIncrement(data[4])
        setDuration(data[5].toNumber())
        setStarted(data[6])
        setStartBlock(data[7].toNumber())
        setStartTime(new Date((await defaultProvider.getBlock(data[7].toNumber())).timestamp * 1000))
        // set variables
        setEndTime(new Date(data[8].toNumber() * 1000))
        setClaimTime(new Date(data[9].toNumber() * 1000))
        setEnded(data[10])
        setHighestBid(data[11])
        setHighestBidder(data[12])
        // set bidders
        // the list will behave differently (historic vs. current) depending on the source
        let bidders: Bidder[] = []
        if (biddersIndexed && useGraph) {
          // from indexed data
          bidders = biddersIndexed
          setIsUsingGraph(true)
          // console.log('Indexed bidders', bidders)
          setAuctionBidders(bidders)
        } else {
          // from smart contract
          bidders = await getBidders(auctionContract, data[13])
          if (auctionBidders.length > 0) {
            if (bidders.length > 0) {
              // console.log('RPC bidders:   ', bidders)
              bidders = bidders.sort(descendingValue)
              setAuctionBidders(bidders)
            }
          }
        }

        setLoading(false)
      })

      // SUBSCRIBE TO AUCTION EVENTS
      auctionContract.on(
        {
          address,
          topics: [],
        },
        (res) => {
          console.log('EVENT    ', res.eventSignature)
          console.log('res.args ', res.args)
          switch (res.event) {
            case 'AuctionStarted':
              setStarted(true)
              setEndTime(new Date(res.args.endTime.toNumber() * 1000))
              break
            case 'HighestBidIncreased':
              handleBid(res.args.bidder.toLowerCase(), res.args.amount, res.args.endTime, res.blockNumber)
              break
            case 'BidWithdrawn':
              handleWithdraw(res.args.bidder.toLowerCase(), res.blockNumber, res.args.withdrawn)
              break
            case 'AuctionEnded':
              setEnded(true)
              break
          }
        }
      )

      return () => {
        auctionContract.removeAllListeners()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [library, address, data, account]
  )

  // INIT AND WATCH WALLET
  useEffect(
    () => {
      if (!library || !account) {
        return
      }

      refetch()
      setIsConnected(true)
      getAccountBalance(account).then((res: BigNumber) => setBalance(res))
      getContractWithSigner()
        .bidders(account)
        .then((res: BidderResponse) => {
          setCurrentBid(res.bids)
          isWalletHighestBidder()
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [library, address, account]
  )

  async function bid(amount: ethers.BigNumberish) {
    if (!walletIsConnected || !library) {
      return
    }
    return await getContractWithSigner().increaseBid({
      value: amount,
    })
  }

  async function withdraw() {
    if (!walletIsConnected || !library) {
      return
    }
    return await getContractWithSigner().withdraw()
  }

  async function close() {
    if (!walletIsConnected || !library) {
      return
    }
    return await getContractWithSigner().close()
  }

  async function returnLosingBids() {
    if (!walletIsConnected || !library) {
      return
    }
    return await getContractWithSigner().returnLosingBids()
  }

  function isWalletHighestBidder() {
    if (!walletIsConnected || !library) {
      return
    }
    return account?.toLowerCase() === auctionHighestBidder.toLowerCase() ? true : false
  }

  async function handleBid(bidder: string, amount: BigNumber, endTime: BigNumber, blockNumber: number) {
    // UPDATE AUCTION
    setHighestBidder(bidder)
    setHighestBid(amount)
    setEndTime(new Date(endTime.toNumber() * 1000))

    // UPDATE BIDDERS
    // if (isUsingGraph) {
    //   // refetch() // propagates changes via gql and useEffect hooks
    //   // NOTE: IF BACKEND CAN'T GET THE GRAPH RESPONSE TO BE PERFORMANT, THEN COMMENT OUT refetch AND DUPLICATE THE BELOW
    // } else {
    const time = (await library.getBlock(blockNumber)).timestamp * 1000
    let bidders = auctionBidders
    const isExistingBidder = auctionBidders.findIndex((b: Bidder) => b.address.toLowerCase() === bidder.toLowerCase())
    if (isExistingBidder === -1) {
      // Insert new bid
      bidders.unshift({ address: bidder, value: amount, time, withdrawn: false })
    } else {
      // Update existing bid
      bidders.splice(isExistingBidder, 1, {
        address: bidder,
        value: amount,
        time: time, // not displayed
        withdrawn: false, // not displayed
      })
      bidders = bidders.sort(descendingValue)
    }
    setAuctionBidders(bidders.map((b) => Object.assign({}, b))) // hack to detect changes
    // }

    // UPDATE WALLET
    if (account?.toLowerCase() === bidder.toLowerCase()) {
      setCurrentBid(amount)
      isWalletHighestBidder()
      setBalance(await getAccountBalance(account))
    }
  }

  async function handleWithdraw(bidder: string, blockNumber: number, withdrawn: true) {
    // UPDATE BIDDERS
    // if (isUsingGraph) {
    //   // refetch() // propagates changes via gql and useEffect hooks
    //   // NOTE: IF BACKEND CAN'T GET THE GRAPH RESPONSE TO BE PERFORMANT, THEN COMMENT OUT refetch AND DUPLICATE THE BELOW
    // } else {
    const time = (await library.getBlock(blockNumber)).timestamp * 1000
    let bidders = auctionBidders
    const isExistingBidder = auctionBidders.findIndex((b: Bidder) => b.address.toLowerCase() === bidder.toLowerCase())
    // Update withdrawn bidder
    bidders.splice(isExistingBidder, 1, {
      address: bidder,
      value: walletCurrentBid,
      time: time, // not displayed
      withdrawn: true, // not displayed
    })
    bidders = bidders.sort(descendingValue)
    setAuctionBidders(bidders.map((b) => Object.assign({}, b))) // hack to detect changes
    // }

    // UPDATE WALLET
    if (account?.toLowerCase() === bidder.toLowerCase()) {
      setCurrentBid(BigNumber.from(0))
      isWalletHighestBidder()
      setBalance(await getAccountBalance(account))
    }
  }

  // TODO: handle Graph updates
  async function updateLosingBids() {
    if (!walletIsConnected || !library) {
      return
    }

    const auctionContract = getContractWithSigner()

    Promise.all([
      auctionContract.highestBid(),
      auctionContract.highestBidder(),
      auctionContract.bidders(account),
      auctionContract.getBiddersList(),
    ]).then(async (data) => {
      setHighestBid(data[0])
      setHighestBidder(data[1])
      setCurrentBid(data[2].bids)
      setAuctionBidders(await getBidders(auctionContract, data[3]))
    })
  }

  function disconnect() {
    setIsConnected(false)
    setCurrentBid(BigNumber.from(0))
    setBalance(undefined)
  }

  /**
   * @returns a sorted list of bidders
   */
  async function getBidders(auctionContract: ethers.Contract, biddersList: string[]) {
    let bidders: Bidder[] = []
    await (async () => {
      for (let addr of biddersList) {
        let res = await auctionContract.bidders(addr)
        bidders.push({ address: addr, value: res.bids })
      }
    })()
    bidders = bidders.sort(descendingValue)
    return bidders
  }

  function descendingValue(a: Bidder, b: Bidder) {
    let diff = b.value!.sub(a.value!)
    return diff.eq(0) ? 0 : diff.lt(0) ? -1 : 1
  }

  function getContractWithSigner() {
    const signer = library.getSigner()
    const auctionContract = new ethers.Contract(address, auctionABI, signer)
    return auctionContract
  }

  async function getAccountBalance(account: string) {
    return await library.getBalance(account)
  }

  return {
    auctionNFTAddress,
    auctionNFTId,
    auctionSeller,
    auctionStartingPrice,
    auctionMinBidIncrement,
    auctionDuration,
    auctionStarted,
    auctionStartBlock,
    auctionStartTime,
    auctionEndTime,
    auctionClaimTime,
    auctionEnded,
    auctionHighestBid,
    auctionHighestBidder,
    auctionBidders,
    auctionLoading,
    walletIsConnected,
    walletCurrentBid,
    walletBalance,
    isUsingGraph,
    bid,
    withdraw,
    close,
    returnLosingBids,
    disconnect,
    updateLosingBids,
    isWalletHighestBidder,
  }
}
