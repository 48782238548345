import { injectionConnector } from '../components/Web3ReactManager/InjectionConnector'
import Swal from 'sweetalert2'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { setupNetwork } from '../utils/setupNetwork'
import { errorNotificationWithMetamask, processingNotification } from './notifications'

export async function connectOrDisconnect(web3: Web3ReactContextInterface, disconnect: () => void) {
  if (web3.active) {
    web3.deactivate()
    disconnect()
    console.log('Disconnected wallet')
  } else {
    try {
      processingNotification({
        title: 'Connecting...',
        text: 'Please wait while your wallet is connected',
      })
      const isAuthorized = await injectionConnector.isAuthorized()
      if (isAuthorized || window.ethereum) {
        try {
          await web3.activate(injectionConnector, undefined, true)
          console.log('Connected to wallet')
          Swal.close()
        } catch (e: any) {
          console.log('Error connecting to wallet', e)
          if (e.message === 'The user rejected the request.') {
            Swal.close()
          } else if (e.message.toLowerCase().startsWith('unsupported chain id')) {
            await setCorrectNetwork(web3.setError)
            await connectOrDisconnect(web3, disconnect)
          } else {
            Swal.fire('Wallet Error', 'Encounted an error when connecting\n' + e, 'error')
          }
        }
      } else {
        console.log("We're not authorized to connect, maybe there is no wallet")
        await errorNotificationWithMetamask({
          title: 'Cannot Connect',
          text: 'No wallet could be found to connect to. You may need to install MetaMask.',
        })
      }
    } catch (e) {
      console.log('Got generic error trying to connect to wallet')
      console.log(e)
    }
  }
}

async function setCorrectNetwork(setError: (error: Error) => void) {
  await setupNetwork()
  setError(undefined as any)
}
