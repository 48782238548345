import { VF } from './common/Layout'
import styled from 'styled-components'
import { AuctionTimer } from './AuctionTimer'
import moment from 'moment'
import { Label } from './common/Typography'
import { Typography } from '../components/common/Typography'

const Note = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  margin: 10px 0 0;
  color: ${({ theme }) => theme.colors.text};
  text-align: right;
`

const Time = styled(Typography)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  padding: 4px;

  background: #393939;
  border-radius: 8px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
`
export type AuctionTimerWithMessageProps = {
  endTime: Date | undefined
  claimTime: Date | undefined
  afterEndTime: boolean | undefined
  afterClaimTime: boolean | undefined
  auctionStarted: boolean | undefined
  auctionEnded: boolean | undefined
}

export function AuctionTimerWithMessage({
  endTime,
  claimTime,
  afterEndTime,
  afterClaimTime,
  auctionStarted,
  auctionEnded,
}: AuctionTimerWithMessageProps) {
  return (
    <VF margin={'10px 0 0'} align={'flex-end'}>
      <Label>{auctionEnded ? `Auction ended` : `Auction ends in`}</Label>
      {endTime && claimTime && auctionStarted ? (
        !afterEndTime ? (
          <VF align={'flex-end'} justify={'flex-end'}>
            <AuctionTimer endTime={endTime} />
          </VF>
        ) : !afterClaimTime ? (
          <VF justify={'flex-end'}>
            <AuctionTimer endTime={claimTime} />
          </VF>
        ) : (
          <VF align={'flex-end'} justify={'flex-end'}>
            <Time>{moment(claimTime).fromNow()}</Time>
            <Note>
              {!auctionEnded
                ? 'Winner may claim the NFT by closing the auction. All losing bids may be withdrawn.'
                : 'Winner has claimed the NFT. All losing bids may be withdrawn.'}
            </Note>
          </VF>
        )
      ) : (
        ''
      )}
    </VF>
  )
}
