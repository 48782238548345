import { TypographyColor, Price, SmallPrice } from './Typography'
import { BigNumber, ethers } from 'ethers'
import { AVAXIcon } from '../icons/AVAX'
import { HF } from '../common/Layout'

export type DisplayPriceProps = {
  price: BigNumber
  color?: TypographyColor
  iconSize?: string
  iconMargin?: string
  size?: string
  padding?: string
  showAvax?: boolean
  small?: boolean
  height?: string
}

export function DisplayPrice({
  padding = '0 12px 0 0',
  price,
  color = TypographyColor.MEDIUM,
  size = '14',
  showAvax = true,
  small = false,
  iconSize = '24px',
  iconMargin = '0 12px 0 0',
  height = 'auto',
}: DisplayPriceProps) {
  return (
    <HF height={height} padding={padding} align={'center'}>
      <AVAXIcon height={iconSize} margin={iconMargin} />
      {small ? (
        <SmallPrice size={size} color={color}>
          {ethers.utils.formatEther(price).toString()} {showAvax ? 'AVAX' : ''}
        </SmallPrice>
      ) : (
        <Price size={size} color={color}>
          {ethers.utils.formatEther(price).toString()} {showAvax ? 'AVAX' : ''}
        </Price>
      )}
    </HF>
  )
}
