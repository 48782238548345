import { Typography } from '../common/Typography'
import { Button2 } from '../common/Button'
import { useWeb3React } from '@web3-react/core'
import { setupNetwork } from '../../utils/setupNetwork'
import { BasicError } from './Basic'

export function WrongNetwork() {
  const { setError } = useWeb3React()

  function setCorrectNetwork() {
    setupNetwork().then(() => {
      setError(undefined as any)
    })
  }

  return (
    <BasicError>
      <Typography size={'22px'} weight={700}>
        You are connected to the wrong network.
      </Typography>
      <br />
      <Button2 onClick={() => setCorrectNetwork()} width={'200px'}>
        Switch Networks
      </Button2>
    </BasicError>
  )
}
