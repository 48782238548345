// Adapted from https://github.com/pancakeswap/pancake-frontend/blob/develop/src/utils/wallet.ts

// Set of helper functions to facilitate wallet setup
import config from '../runtime/config'

/**
 * Prompt the user to add Avalanche as a network on Metamask, or switch to Avalanche if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export function setupNetwork() {
  const provider: any = (window as Window).ethereum
  return provider?.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: `0x${config.app.supportedChainIds[0].toString(16)}`,
        chainName: config.app.chainName,
        nativeCurrency: {
          name: 'AVAX',
          symbol: 'AVAX',
          decimals: 18,
        },
        rpcUrls: [config.app.networkUrl],
        blockExplorerUrls: [config.app.explorerUrl],
      },
    ],
  })
}
