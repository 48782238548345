import { Arrow, ArrowDirection } from './common/Arrow'
import { HF, VF } from './common/Layout'
import { NFTImage, NFTImageContainer_Carousel as NFTCarousel } from './NFTImage'
import styled from 'styled-components'
import { useState } from 'react'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import { Label2, Typography } from '../components/common/Typography'
import { packMetadata } from '../assets/packMetadata'
import { getImageNameFromURI } from '../utils/getImageNameFromURI'

export interface CarouselProps {
  id: string
}
const Dot = styled.div<{
  active?: boolean
}>`
  background: ${({ active, theme }) => (active ? theme.colors.black : 'rgba(0, 0, 0, 0.1)')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 4px;
  cursor: pinter;
`
export function Carousel({ id }: CarouselProps) {
  const [currentIndex, changeIndex] = useState(0)
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()
  const metadata = packMetadata[id]

  let nfts = []

  for (const key in metadata) {
    let imageName = getImageNameFromURI(metadata[key].image)
    nfts.push({
      ...metadata[key],
      of: 'contain',
      op: '50% 50%',
      imageName: `/packs/${id}/${imageName}`,
      id: key,
    })
  }

  function goToPrevSlide() {
    currentIndex === 0 ? changeIndex(nfts.length - 1) : changeIndex(currentIndex - 1)
  }
  function goToNextSlide() {
    changeIndex((currentIndex + 1) % nfts.length)
  }

  return (
    <VF width={'100%'} align={'center'}>
      {/* MURAL */}
      <HF width={'100%'} justify={'space-between'} align="center">
        {!upToSmall && <Arrow direction={ArrowDirection.LEFT} handleClick={goToPrevSlide} />}
        <NFTCarousel
          onClick={() => {
            upToSmall && goToNextSlide()
          }}
        >
          <NFTImage src={nfts[currentIndex]!.imageName} alt={nfts[currentIndex]!.name} />
        </NFTCarousel>
        {!upToSmall && <Arrow direction={ArrowDirection.RIGHT} handleClick={goToNextSlide} />}
      </HF>

      {/* DOTS */}
      <HF margin="16px 0">
        {nfts.map((img, index) => (
          <Dot key={index} onClick={() => changeIndex(index)} active={index === currentIndex} />
        ))}
      </HF>

      {/* DESCRIPTION */}
      {upToMedium ? null : (
        <VF margin="16px 0">
          <VF margin="16px 0">
            <Label2>Name</Label2>
            <Typography>{nfts[currentIndex]!.name}</Typography>
          </VF>
          <VF margin="16px 0">
            <Label2>Artist</Label2>
            <Typography>{nfts[currentIndex]!.artist}</Typography>
          </VF>
          <VF margin="16px 0">
            <Label2>NFT</Label2>
            <Typography>{nfts[currentIndex]!.id}</Typography>
          </VF>
        </VF>
      )}
    </VF>
  )
}
