export function LinkIcon({
  width = '12',
  height = '12',
  color,
  style = null,
}: {
  width?: string
  height?: string
  color?: string
  style?: any
}) {
  return (
    <svg style={style} width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="2" height="10" fill={color ?? 'currentColor'} />
      <rect x="10" y="5" width="2" height="5" fill={color ?? 'currentColor'} />
      <rect y="12" width="2" height="12" transform="rotate(-90 0 12)" fill={color ?? 'currentColor'} />
      <rect y="2" width="2" height="8" transform="rotate(-90 0 2)" fill={color ?? 'currentColor'} />
    </svg>
  )
}
