interface Quantities {
  [name: string]: number
}

export function getQty(qty: number) {
  let quantities: Quantities = {}
  for (let i = 1; i < qty + 1; i++) {
    quantities[i.toString()] = i
  }
  return quantities
}
