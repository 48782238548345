import Swal from 'sweetalert2'
import { BigNumber, ethers } from 'ethers'
import { errorNotification, processingNotification, successNotificationWithExplorer } from './notifications'
import { getQty } from '../utils/getQty'

export async function executeBuy(
  price: BigNumber | undefined,
  walletAddress: any,
  walletCurrentBalance: BigNumber | undefined,
  func: (packsBought: number) => Promise<any>
) {
  if (!price || !walletCurrentBalance) {
    return
  }

  try {
    const { isConfirmed, value: qty } = await Swal.fire({
      title: 'Buy NFT Pack',
      html: `      
      <div class="ava_bid_data"> 
        <span class="ava_label">Price</span>
        <span class="ava_value">${ethers.utils.formatEther(price)} AVAX</span>
        <span class="ava_label">Your Balance</span>
        <span class="ava_value">${ethers.utils.formatEther(walletCurrentBalance)} AVAX</span>                
      </div>`,
      input: 'select',
      inputAttributes: { autofocus: 'true' },
      inputOptions: getQty(10),
      inputPlaceholder: 'Qty',
      inputValidator: (value: any) => {
        return new Promise((resolve) => {
          if (!value) {
            resolve('Must select a quantity.')
          } else if (price.mul(value).gt(walletCurrentBalance)) {
            resolve('Total price exceeds balance')
          } else {
            resolve(null)
          }
        })
      },
      showCancelButton: true,
      width: '705px',
      confirmButtonText: 'Buy Pack',
    })

    if (isConfirmed) {
      processingNotification({})
      const tx = await func(qty)
      await tx.wait(1)

      const total = price.mul(qty)
      const displayTotal = ethers.utils.formatEther(total).toString().toLocaleString()

      await successNotificationWithExplorer({
        text: `Thanks for buying ${qty} packs (${displayTotal} AVAX).`,
        address: walletAddress,
      })
    }
  } catch (e: any) {
    console.log(e)
    if (e.message && e.data) {
      await errorNotification({ title: e.message, text: e.data.message })
    }
  } finally {
    Swal.close()
  }
}
