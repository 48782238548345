import { VF } from './common/Layout'
import { BigNumber } from 'ethers'
import styled from 'styled-components'
import { Typography, TypographyColor, Label } from './common/Typography'
import { DisplayPrice } from './common/DisplayPrice'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'

const BidText = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
`

type WinnerSettings = {
  winAmount: BigNumber
  nextMinBid: BigNumber
  ended: boolean | undefined
  color?: TypographyColor | undefined
}

export function CurrentBid({ winAmount, nextMinBid, ended, color }: WinnerSettings) {
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()
  return (
    <VF margin={'10px 0 0'}>
      <Label color={color ? color : TypographyColor.MEDIUM}>{ended ? `Winning bid` : `Min bid`}</Label>
      <BidText color={color ? color : TypographyColor.MEDIUM}>
        <DisplayPrice
          color={color ? color : TypographyColor.MEDIUM}
          price={ended ? winAmount : nextMinBid}
          showAvax={upToSmall ? false : upToMedium ? true : true}
        />
      </BidText>
    </VF>
  )
}
