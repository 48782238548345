import styled from 'styled-components'
import { HF, VF } from './common/Layout'
import { PoweredByAvalancheIcon } from './icons/PoweredByAvalanche'
import { KalaoIcon } from './icons/Kalao'
import { TwitterIcon } from './icons/Twitter'
import { InstagramIcon } from './icons/Instagram'
import logoWhite from '../assets/JMPLogo.png'

const Page = styled(VF)`
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.black};
`

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  margin: 61px 0;
  width: 90%;
  max-width: 1200px;
  align-self: center;

  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};
`
const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  flex-direction: row-reverse;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 0 0 32px 0;
    flex-direction: row;
  `};
`

const IconLink = styled(ExternalLink)`
  margin-right: 60px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: 32px;
  `};
`

const Logo = styled.img`
  height: 28px;
  margin-right: 60px;
  flex-order: 1;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-order: 2;
    margin-right: 0;  
  `};
`

const Engineers = styled.div`
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: space-between;
`

const EngineerLink = styled(ExternalLink)`
  &:first-of-type {
    margin-right: 60px;

    ${({ theme }) => theme.mediaWidth.upToMedium`
      margin-right: 32px;
    `};
  }
`

export function Footer() {
  return (
    <Page>
      <ContentFooter>
        <Icons>
          <HF>
            <IconLink href="https://www.instagram.com/jasonmpeterson/" target="_blank">
              <InstagramIcon />
            </IconLink>
            <IconLink href={'https://twitter.com/Jasonmpeterson/'} target="_blank">
              <TwitterIcon margin="3px 0 0" />
            </IconLink>
          </HF>
          <Logo src={logoWhite} />
        </Icons>
        <Engineers>
          <EngineerLink href={'https://avax.network/'} target="_blank">
            <PoweredByAvalancheIcon height={'64'} />
          </EngineerLink>
          <EngineerLink href={'https://kalao.io/'} target="_blank">
            <KalaoIcon height={'40'} />
          </EngineerLink>
        </Engineers>
      </ContentFooter>
    </Page>
  )
}
