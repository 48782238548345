import { BigNumber, ethers } from 'ethers'
import styled from 'styled-components'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import { VF } from './common/Layout'
import { Typography, TypographyColor, Announcement } from './common/Typography'

const CongratsText = styled(Typography)`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  text-align: center;
  margin: 24px 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    line-height: 16px;
  `};
`

type WinnerSettings = {
  winner: string
  winAmount: BigNumber
}

export function Winner({ winner, winAmount }: WinnerSettings) {
  const { upToSmall } = useGetIsMobileScreenSize()
  return (
    <VF width="100%" align="center">
      <Announcement
        color={TypographyColor.PRIMARY}
        size={upToSmall ? '28px' : '64px'}
        lineHeight={upToSmall ? '32px' : '65px'}
      >
        Congratulations
      </Announcement>
      <CongratsText>
        The winner of the {upToSmall && <br />} JMP Mural NFT is
        <br />
        {winner}
        <br />
        with a bid of {winAmount ? Number(ethers.utils.formatEther(winAmount)) : null} AVAX.
      </CongratsText>
    </VF>
  )
}
