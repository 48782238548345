import styled from 'styled-components'
import { HF, VF } from './common/Layout'
import { executeBid } from '../services/executeBid'
import { executeWithdraw } from '../services/executeWithdraw'
import { executeClose } from '../services/executeClose'
import { executeReturnLosingBids } from '../services/executeReturnLosingBids'
import { Button1, Button2 } from './common/Button'
import { BigNumber, BigNumberish, ethers } from 'ethers'
import { Bidder } from '../hooks/useAuction'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import { TwitterLogo } from './logos/Twitter'
import { twitterIntent } from '../utils/twitterIntent'

const CTAContainer = styled(HF)`
  // border: 1px solid rgba(248, 178, 106, 0.25);
`
const BidStatus = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #ffffff;
`
const TwitterButton = styled(Button2)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    align-self: center;
  `};
`

export type AuctionCTAProps = {
  walletIsConnected: boolean
  afterClaimTime: boolean | undefined
  nextMinBid: BigNumber
  walletCurrentBid: ethers.BigNumber
  auctionEnded: boolean | undefined
  endTime: Date | undefined
  auctionBidders: Bidder[]
  winAmount: BigNumber
  isWalletHighestBidder: () => boolean | undefined
  bid: (amount: BigNumberish) => Promise<any>
  withdraw: () => Promise<any>
  close: () => Promise<any>
  returnLosingBids: () => Promise<any>
  updateLosingBids: () => Promise<void>
}

export function AuctionCTA({
  walletIsConnected,
  afterClaimTime,
  nextMinBid,
  walletCurrentBid,
  auctionEnded,
  endTime,
  auctionBidders,
  winAmount,
  isWalletHighestBidder,
  bid,
  withdraw,
  close,
  returnLosingBids,
  updateLosingBids,
}: AuctionCTAProps) {
  const { upToSmall } = useGetIsMobileScreenSize()
  const enableReturnLosingBids = false

  return (
    <CTAContainer direction={upToSmall ? 'column' : 'row'}>
      {/* CALL-TO-ACTION BUTTONS */}
      <VF align={'center'} justify={'center'} margin={upToSmall ? '12px 0 0' : '12px 0 0'}>
        <HF>
          {/* WALLET IS CONNECTED */}
          {walletIsConnected ? (
            /* BEFORE CLAIMTIME */
            !afterClaimTime ? (
              <HF>
                {/* ANY CURRENT BIDDER CAN WITHDRAW, EXCEPT HIGHEST BIDDER */}
                {Number(ethers.utils.formatEther(walletCurrentBid)) === 0 || isWalletHighestBidder() ? (
                  ''
                ) : (
                  <Button2
                    onClick={() => executeWithdraw(walletCurrentBid, isWalletHighestBidder, withdraw)}
                    margin="0 16px 0 0"
                  >
                    Withdraw
                  </Button2>
                )}
                {/* ANYONE CAN BID */}
                <Button1 onClick={() => executeBid(walletCurrentBid, nextMinBid, endTime, bid)} margin={'0 16px 0 0'}>
                  Bid
                </Button1>
              </HF>
            ) : /* AFTER CLAIMTIME */
            !auctionEnded ? (
              /* AUCTION IS OPEN */
              /* ANYONE CAN CLOSE THE AUCTION */
              <HF>
                <Button2 onClick={() => executeClose(close)} margin={'0 16px 0 0'}>
                  CLOSE THE AUCTION
                </Button2>
                {/* ANYONE CAN WITHDRAW, EXCEPT THE WINNER */}
                {Number(ethers.utils.formatEther(walletCurrentBid)) === 0 || isWalletHighestBidder() ? (
                  ''
                ) : (
                  <Button2
                    onClick={() => executeWithdraw(walletCurrentBid, isWalletHighestBidder, withdraw)}
                    margin={'0 16px 0 0'}
                  >
                    Withdraw
                  </Button2>
                )}
              </HF>
            ) : (
              <HF>
                {/* AUCTION IS CLOSED */}
                {/* ANYONE CAN WITHDRAW, EXCEPT THE WINNER */}
                {Number(ethers.utils.formatEther(walletCurrentBid)) === 0 || isWalletHighestBidder() ? (
                  ''
                ) : (
                  <Button2
                    onClick={() => executeWithdraw(walletCurrentBid, isWalletHighestBidder, withdraw)}
                    margin={'0 16px 0 0'}
                  >
                    Withdraw
                  </Button2>
                )}
                {auctionBidders.find((bidder) => bidder.value?.gt(0)) && enableReturnLosingBids ? (
                  <Button2
                    onClick={() => executeReturnLosingBids(returnLosingBids, updateLosingBids)}
                    margin={'0 16px 0 0'}
                  >
                    Return Losing Bids
                  </Button2>
                ) : null}
              </HF>
            )
          ) : (
            /* IF WALLET IS DISCONNECTED */
            <Button1
              onClick={() => {
                window.open('https://www.youtube.com/watch?v=qbQEsogDL3Y', '_blank')
              }}
              margin={'0 24px 0 0'}
            >
              How to Bid
            </Button1>
          )}
          <TwitterButton as="a" target="_blank" href={twitterIntent(winAmount, endTime)}>
            <HF padding="0 12px 0 0">
              <TwitterLogo height={'16'} color={'black'} />
            </HF>
            Share
          </TwitterButton>
        </HF>
        {/* USER BALANCE */}
        <VF align={'center'} justify={'center'} padding={upToSmall ? '16px 0 0' : '16px 0 0'}>
          {walletIsConnected ? (
            Number(ethers.utils.formatUnits(walletCurrentBid)) !== 0 ? (
              <BidStatus>My Current Bid: {Number(ethers.utils.formatEther(walletCurrentBid))} AVAX</BidStatus>
            ) : null
          ) : null}
          {isWalletHighestBidder() ? <BidStatus>You are the highest bidder</BidStatus> : null}
        </VF>
      </VF>
    </CTAContainer>
  )
}
