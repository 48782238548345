import React, { useRef } from 'react'
import { VF, Content } from '../components/common/Layout'
import { useAuction } from '../hooks/useAuction'
import { usePack } from '../hooks/usePack'
import { BigNumber } from 'ethers'
import { Description } from '../components/Description'
import { AuctionHero } from '../components/AuctionHero'
import { PacksHero } from '../components/PacksHero'
import { About } from '../components/About'
import { Footer } from '../components/Footer'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'

interface LandingPageProps {
  auctionInfo: ReturnType<typeof useAuction>
  afterEndTime: boolean | undefined
  afterClaimTime: boolean | undefined
  nextMinBid: BigNumber
  startingBid: BigNumber
  artPack: ReturnType<typeof usePack>
  artistPack: ReturnType<typeof usePack>
}

export default function Landing({ auctionInfo, afterClaimTime, afterEndTime, nextMinBid, artPack, artistPack }: LandingPageProps) {
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()
  const { auctionEnded, auctionHighestBid } = auctionInfo

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>, offset: number) => {
    if (ref.current !== null) {
      window.scrollTo(0, ref.current.offsetTop - offset)
    }
  }

  const packsRef = useRef<HTMLDivElement>(null)
  const offset = upToSmall ? 110 : 230

  if (window.location.hash.includes('#packs')) {
    scrollToRef(packsRef, offset)
  }

  return (
    <Content align={'center'} marginLR={upToSmall ? '0px' : upToMedium ? 'auto' : 'auto'}>
      <Description />
      <VF width={'100%'} direction={afterClaimTime ? 'column-reverse' : 'column'}>
        <AuctionHero
          endTime={afterEndTime ? auctionInfo.auctionClaimTime : auctionInfo.auctionEndTime}
          afterClaimTime={afterClaimTime}
          nextMinBid={nextMinBid}
          winAmount={auctionHighestBid}
          ended={auctionEnded}
        />
        <PacksHero ref={packsRef} artPack={artPack} artistPack={artistPack} />
      </VF>
      <About />
      <Footer />
    </Content>
  )
}
