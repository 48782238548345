import { AppConfig } from '../@types/config'

export const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID as string)
export const TEST_CHAIN_ID = parseInt(process.env.REACT_APP_TEST_CHAIN_ID as string)

export const MAINNET_APP_CONFIG: AppConfig = {
  networkId: process.env.REACT_APP_NETWORK_ID_ETH as string,
  supportedChainIds: [CHAIN_ID],
  chainName: process.env.REACT_APP_CHAIN_NAME as string,
  networkUrl: process.env.REACT_APP_PROVIDER_URL as string,
  explorerUrl: process.env.REACT_APP_EXPLORER_URL as string,
  graphqlUrl: process.env.REACT_APP_AUCTION_GRAPH_URL as string,
}

export const TESTNET_APP_CONFIG: AppConfig = {
  networkId: process.env.REACT_APP_TEST_NETWORK_ID_ETH as string,
  supportedChainIds: [TEST_CHAIN_ID],
  chainName: process.env.REACT_APP_TEST_CHAIN_NAME as string,
  networkUrl: process.env.REACT_APP_TEST_PROVIDER_URL as string,
  explorerUrl: process.env.REACT_APP_TEST_EXPLORER_URL as string,
  graphqlUrl: process.env.REACT_APP_TEST_AUCTION_GRAPH_URL as string,
}
