import { ethers, Signer } from 'ethers'
import { Provider } from '@ethersproject/providers'
import wavaxABI from '../abi/wavax.abi.json'
import erc20ABI from '../abi/erc20.abi.json'
import usdtLogo from '../assets/Tether-icon.png'
import avaxLogo from '../assets/Avax-icon.png'
import { ContractTokens } from '../@types/tokens'

export enum TokenName {
  AVAX = 'AVAX',
  USDT = 'USDT',
}

export const MAINNET_TOKENS: ContractTokens = {
  /**
   * contract @link https://cchain.explorer.avax-test.network/address/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/transactions
   * github @link https://github.com/ava-labs/wrapped-assets
   */
  AVAX: {
    name: 'AVAX',
    symbol: 'AVAX',
    img: avaxLogo,
    address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    contract: (library?: Signer | Provider) =>
      new ethers.Contract('0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', wavaxABI, library),
    stablecoin: false,
    decimals: 18,
    color: '#E84142',
  },
  /**
   * contract @link https://cchain.explorer.avax-test.network/address/0xc7198437980c041c805A1EDcbA50c1Ce5db95118/transactions
   */
  USDT: {
    name: 'USDT',
    symbol: 'USDT',
    img: usdtLogo,
    address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    contract: (library?: Signer | Provider) =>
      new ethers.Contract('0xc7198437980c041c805A1EDcbA50c1Ce5db95118', erc20ABI, library),
    stablecoin: true,
    decimals: 6,
    color: '#27A17C',
  },
}

export const TESTNET_TOKENS: ContractTokens = {
  /**
   * contract @link https://cchain.explorer.avax-test.network/address/0xd00ae08403B9bbb9124bB305C09058E32C39A48c/transactions
   * github @link https://github.com/ava-labs/wrapped-assets
   */
  AVAX: {
    name: 'AVAX',
    symbol: 'AVAX',
    img: avaxLogo,
    address: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
    contract: (library?: Signer | Provider) =>
      new ethers.Contract('0xd00ae08403B9bbb9124bB305C09058E32C39A48c', wavaxABI, library),
    stablecoin: false,
    decimals: 18,
    color: '#E84142',
  },
  /**
   * contract @link https://cchain.explorer.avax-test.network/address/0x251907a5eCEab5ef4a92bf3d67B651C4df605A87/transactions
   */
  USDT: {
    name: 'USDT',
    symbol: 'USDT',
    img: usdtLogo,
    address: '0x5F343796b6E09fF3F0fBe97898ef29a5CC2647f4',
    contract: (library?: Signer | Provider) =>
      new ethers.Contract('0x5F343796b6E09fF3F0fBe97898ef29a5CC2647f4', erc20ABI, library),
    stablecoin: true,
    decimals: 6,
    color: '#27A17C',
  },
}
