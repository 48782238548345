import { Typography } from './common/Typography'
import styled from 'styled-components'

interface PackCountProps {
  packsLeft: string
  maxPacks: string
}

const Count = styled(Typography)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px;

  background: #393939;
  border-radius: 8px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
`

export function PackCount({ packsLeft, maxPacks }: PackCountProps) {
  return (
    <Count>
      {packsLeft.toString()} of {maxPacks.toString()} left
    </Count>
  )
}
