import { VF } from './common/Layout'
import styled from 'styled-components'
import { Typography } from './common/Typography'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'

const Hero = styled(VF)`
  // min-height: 345px;
`

const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  margin-block-start: 0;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  width: 100%;
  max-width: 400px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 24px;
    line-height: 29px; 
  `};
`

const Textbody = styled(Typography)`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  max-width: 400px;

  color: ${({ theme }) => theme.colors.textLight};
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

export function Description() {
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()

  return (
    <Hero
      justify="center"
      align="center"
      width="100%"
      padding={upToSmall ? '52px 15px' : upToMedium ? '100px 30px' : '100px 0'}
    >
      <Title>Yeah Probably Nothing</Title>
      <Textbody>
        An NFT series by world-renowned photographer Jason M Peterson – featuring a physical mural of Kanye West located
        in Chicago on Lake Street in the West Loop and limited edition NFTs of Jason's esteemed photographs.
      </Textbody>
    </Hero>
  )
}
