import { VF, HF } from './common/Layout'
import styled from 'styled-components'
import { BigNumber } from 'ethers'
import { Winner } from '../components/Winner'
import { abbreviateAddress } from '../utils/abbreviateAddress'
import moment from 'moment'
import { Bidder } from '../hooks/useAuction'
import { Label, Label2 } from './common/Typography'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import React from 'react'
import { AddressIcon } from './icons/Address'
import { DisplayPrice } from './common/DisplayPrice'

const TableContainer = styled(VF)`
  width: 100%;
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.colors.text};
`

const Heading = styled(Label)`
  padding-bottom: 8px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;
`

const Table = styled.div<{
  isUsingGraph: boolean
}>`
  display: grid;
  grid-template-columns: ${({ isUsingGraph }) =>
    isUsingGraph
      ? `minmax(44px, 44px) minmax(75px, 4fr) minmax(55px, 1fr)`
      : `minmax(44px, 44px) minmax(75px, 4fr) minmax(55px, 1fr)`};
`

const Cell = styled(VF)<{
  padding?: string
}>`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};
  padding: ${({ padding }) => (padding ? padding : '16px 0')};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px 2px;
    font-size: 14px;
  `};
`

const MessageRow = styled.div`
  width: 100%;
  font-family: Inter;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 24px 0;
`

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`

const AddressLink = styled(ExternalLink)`
  font-weight: bold;
  margin-right: 6px;
`
export interface LeaderboardSettings {
  loading: boolean
  bidders: Array<Bidder>
  ended: boolean | undefined
  winner: string
  winAmount: BigNumber
  account: string | null | undefined
  isUsingGraph: boolean
}

export function Leaderboard({
  loading,
  bidders,
  ended,
  winner,
  winAmount,
  account,
  isUsingGraph,
}: LeaderboardSettings) {
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()

  const abbreviateDateString = (date: string) => {
    return !upToSmall
      ? date
      : date
          .replace(/ minute(s)?/, 'm')
          .replace(/ hour(s)?/, 'h')
          .replace(' days', 'd')
          .replace('a day', '1d')
  }

  const explorerURL =
    process.env.REACT_APP_NETWORKID === '1'
      ? process.env.REACT_APP_EXPLORER_URL
      : process.env.REACT_APP_TEST_EXPLORER_URL

  return (
    <VF
      align={'flex-start'}
      width={upToSmall ? '' : upToMedium ? '' : '100%'}
      margin={upToSmall ? '64px 15px 0' : upToMedium ? '64px 30px 0' : '64px 0 0'}
    >
      <VF align={'center'} width={'100%'} padding={upToSmall ? '0' : '0'}>
        {ended ? (
          <Winner winner={winner} winAmount={winAmount} />
        ) : (
          <TableContainer className="tableContainer">
            <HF>
              <Heading>Bid history</Heading>
            </HF>
            <Table isUsingGraph={isUsingGraph}>
              {!loading &&
                bidders.length > 0 &&
                bidders.map((bidder, key) => {
                  const ownRow = account?.toLowerCase() === bidder.address.toLowerCase()
                  return (
                    <React.Fragment key={key}>
                      <Cell align={'flex-start'} justify={'flex-start'} padding={'18px 0 16px'}>
                        <AddressIcon opacity={ownRow ? '1' : '.2'} margin="2px 0 0" />
                      </Cell>
                      <Cell align={'flex-start'} justify={'flex-start'} padding={'18px 0 16px'}>
                        <HF align={'flex-end'}>
                          <AddressLink
                            href={`${explorerURL}${process.env.REACT_APP_ADDRESS_BASE_URL}${bidder.address}`}
                            target="_blank"
                          >
                            {abbreviateAddress(bidder.address, 4)}
                          </AddressLink>{' '}
                          <Label2 padding={'0 8px 2px 0'}>placed a bid</Label2>
                        </HF>
                        <Label2>
                          {isUsingGraph ? (
                            <>{bidder.time ? abbreviateDateString(moment(bidder.time).fromNow()) : null}</>
                          ) : null}
                        </Label2>
                      </Cell>
                      <Cell align={'flex-start'} justify={'flex-start'} padding={'18px 0 16px'}>
                        <HF align={'flex-end'} height={'21px'}>
                          {bidder.value ? (
                            <DisplayPrice
                              price={bidder.value}
                              padding={'0 0 0 0'}
                              height={'21px'}
                              size={'14'}
                              iconSize={'16'}
                              iconMargin={'0 6px 0 0'}
                              showAvax={false}
                              small={true}
                            ></DisplayPrice>
                          ) : null}
                        </HF>
                        <HF align={'flex-end'} margin={'0'}>
                          <Label2 padding={'0 8px 2px 0'}></Label2>
                        </HF>
                      </Cell>
                    </React.Fragment>
                  )
                })}
            </Table>
          </TableContainer>
        )}
        {bidders.length === 0 && <MessageRow>There are no bidders.</MessageRow>}
        {loading && <MessageRow>Loading</MessageRow>}
      </VF>
    </VF>
  )
}
