import Swal from 'sweetalert2'
import { BigNumber, ethers } from 'ethers'
import { errorNotification, processingNotification, successNotificationWithTweet } from './notifications'

export async function executeBid(
  walletCurrentBid: BigNumber,
  nextMinBid: BigNumber,
  endTime: Date | undefined,
  func: (a: BigNumber) => Promise<any>
) {
  try {
    const { isConfirmed, value: bidAmountAvax } = await Swal.fire({
      title: 'Place Your Bid',
      html: `
      <div class="ava_bid_data"> 
        <span class="ava_label">Your Current Bid: ${ethers.utils.formatEther(walletCurrentBid)} AVAX</span>
        <span class="ava_value">You must Bid at least: ${ethers.utils.formatEther(
          nextMinBid.sub(walletCurrentBid)
        )} AVAX ${
        !walletCurrentBid.eq(BigNumber.from(0)) ? `to lead auction at ${ethers.utils.formatEther(nextMinBid)} AVAX` : ``
      }</span>
       </div>`,
      input: 'text',
      inputAttributes: { autofocus: 'true' },
      inputValidator: (value: string) => {
        if (!value) {
          return 'Please input a value'
        } else if (isNaN(+value)) {
          return 'Please input a number'
        } else if (ethers.utils.parseEther(value).add(walletCurrentBid).lt(nextMinBid)) {
          return 'Bid must exceed current highest bid'
        } else {
          return null
        }
      },
      width: '705px',
      confirmButtonText: 'Place bid',
    })

    if (isConfirmed) {
      processingNotification({})
      const tx = await func(ethers.utils.parseUnits(bidAmountAvax.toString()))
      await tx.wait(1)
      await successNotificationWithTweet({
        text: 'Your bid was submitted successfully',
        bid: ethers.utils.parseUnits(bidAmountAvax.toString()),
        endTime,
      })
    }
  } catch (e: any) {
    console.log(e)
    if (e.message && e.data) {
      await errorNotification({ title: e.message, text: e.data.message })
    }
  } finally {
    Swal.close()
  }
}
