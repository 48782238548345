import { VF, HF } from './common/Layout'
import styled from 'styled-components'
import { Typography, Title3 } from './common/Typography'
import { BigNumber, ethers } from 'ethers'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import moment from 'moment'

const DetailsContainer = styled(VF)`
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-radius: 0;
  `};
`

const Textbody = styled(Typography)`
  margin-top: 10px;
`

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export interface AuctionDetailsProps {
  contractAddress: string | undefined
  duration: number | undefined
  startingBid: BigNumber
  started: boolean | undefined
  startBlock: number | undefined
  startTime: Date | undefined
  endTime: Date | undefined
  claimTime: Date | undefined
  afterEndTime: boolean | undefined
}

export function AuctionDetails({
  contractAddress,
  duration,
  startingBid,
  started,
  startBlock,
  startTime,
  endTime,
  claimTime,
  afterEndTime,
}: AuctionDetailsProps) {
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()

  const explorerURL =
    process.env.REACT_APP_NETWORKID === '1'
      ? process.env.REACT_APP_EXPLORER_URL
      : process.env.REACT_APP_TEST_EXPLORER_URL

  return (
    <DetailsContainer
      align={'center'}
      width={upToSmall ? '' : upToMedium ? '' : '100%'}
      margin={upToSmall ? '0' : upToMedium ? '20px 30px 0' : '40px 0 0'}
      padding={upToSmall ? '32px' : upToMedium ? '32px' : '32px'}
    >
      <VF width={'100%'}>
        {/* DETAILS */}
        <HF width={'100%'}>
          <Title3 style={{ marginTop: 0 }}>Auction Details</Title3>
        </HF>
        <HF width={'100%'}>
          <Textbody>
            <Typography>
              The marketplace was developed in joint collaboration with{' '}
              <ExternalLink href={'https://kalao.io/'} target="_blank">
                Kalao
              </ExternalLink>{' '}
              and{' '}
              <ExternalLink href={'https://www.avalabs.org/'} target="_blank">
                Ava Labs
              </ExternalLink>
              .
            </Typography>
          </Textbody>
        </HF>

        {/* Phase One */}
        <HF width={'100%'}>
          <Title3>Phase One</Title3>
        </HF>
        <HF>
          <Textbody>
            <Typography>
              {started && startTime ? (
                <>
                  The auction started at {startTime.toLocaleString()} (
                  <ExternalLink
                    href={`${explorerURL}${process.env.REACT_APP_BLOCK_BASE_URL}${startBlock}`}
                    target="_blank"
                    style={{ wordBreak: 'break-all' }}
                  >
                    {moment(startTime).fromNow()}
                  </ExternalLink>
                  ).
                </>
              ) : (
                <>
                  The auction will start when someone transfers the NFT to the{' '}
                  <ExternalLink
                    href={`${explorerURL}${process.env.REACT_APP_ADDRESS_BASE_URL}${contractAddress}`}
                    target="_blank"
                    style={{ wordBreak: 'break-all' }}
                  >
                    auction contract
                  </ExternalLink>
                  .
                </>
              )}
              {duration ? (
                <>
                  {' '}
                  The auction {afterEndTime ? 'lasted' : 'will last'} {moment.duration(duration, 'seconds').humanize()}.
                </>
              ) : null}

              {startingBid
                ? ` The minimum bid was set to ${Number(ethers.utils.formatEther(startingBid))} AVAX.`
                : null}
            </Typography>
            {endTime ? (
              <Typography>
                The end time for phase one can change. Currently, the auction is scheduled to end at{' '}
                {endTime.toLocaleString()} ({moment(endTime).fromNow()}).
              </Typography>
            ) : null}

            <Typography>
              In the last ten minutes of the auction, there will be bid extensions of three minutes for each bid placed.
            </Typography>
          </Textbody>
        </HF>

        {/* Phase 2 */}
        <HF width={'100%'}>
          <Title3>Phase Two</Title3>
        </HF>
        <HF>
          <Textbody>
            <Typography>
              In the 24 hours after the auction has closed, it will still be possible to re-open the auction by entering
              a bid that is at least 30% greater than the closing bid. If this occurs, the auction will be re-opened for
              an additional 24 hours.
            </Typography>
            {claimTime ? (
              <Typography>
                The second phase is scheduled to complete, making the Mural NFT claimable, at{' '}
                {claimTime.toLocaleString()} ({moment(claimTime).fromNow()}).
              </Typography>
            ) : null}
          </Textbody>
        </HF>
      </VF>
    </DetailsContainer>
  )
}
