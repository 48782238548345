export function TwitterIcon({ height = '26px', margin = '' }: { height?: string; margin?: string }) {
  return (
    <svg height={height} style={{ margin }} viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.628866 23.1265C3.59153 25.0065 7.03953 25.9999 10.5995 25.9999C15.8129 25.9999 20.5902 23.9972 24.0529 20.3612C27.3662 16.8812 29.1889 12.2052 29.0982 7.45986C30.3542 6.38519 31.8315 4.33319 31.8315 2.33319C31.8315 1.56652 30.9995 1.07986 30.3249 1.47052C29.1449 2.16386 28.0689 2.34519 26.9622 2.03452C24.7022 -0.168144 21.3382 -0.632144 18.5035 0.895856C16.0262 2.22919 14.6489 4.66919 14.7729 7.33586C10.5875 6.82519 6.72087 4.72652 4.02487 1.46519C3.5822 0.933189 2.7422 0.995856 2.3902 1.59852C1.09153 3.82252 1.10487 6.39986 2.2342 8.48119C1.69687 8.57586 1.36487 9.02785 1.36487 9.51719C1.36487 11.6092 2.3062 13.5319 3.8222 14.8439C3.53953 15.1159 3.4462 15.5199 3.5662 15.8799C4.23287 17.8825 5.7422 19.4479 7.62887 20.2319C5.57687 21.2119 3.30753 21.5385 1.28753 21.2905C0.242199 21.1492 -0.273801 22.5545 0.628866 23.1265ZM10.8729 20.5745C11.6209 19.9999 11.2235 18.8025 10.2849 18.7825C8.63153 18.7479 7.1262 17.9332 6.18887 16.6519C6.64087 16.6225 7.10887 16.5532 7.5542 16.4332C8.56887 16.1585 8.52087 14.6945 7.4902 14.4879C5.61953 14.1119 4.15153 12.7492 3.60087 10.9945C4.10353 11.1185 4.61553 11.1879 5.1262 11.1972C6.1382 11.2025 6.52087 9.90519 5.69553 9.36252C3.83553 8.13719 3.0422 5.97319 3.56753 3.93852C6.81553 7.22919 11.2222 9.21186 15.8835 9.43586C16.5515 9.47719 17.0529 8.84919 16.9062 8.21319C16.2729 5.46786 17.8062 3.54386 19.4529 2.65719C21.0822 1.77719 23.6982 1.50252 25.7035 3.60652C26.2995 4.23452 28.3102 4.25852 29.3329 4.01986C28.8742 4.88386 28.1689 5.70386 27.5089 6.16519C27.2275 6.36252 27.0662 6.69052 27.0835 7.03319C27.2982 11.4132 25.6662 15.7679 22.6062 18.9799C19.5235 22.2159 15.2609 23.9985 10.6009 23.9985C8.74753 23.9985 6.9302 23.6972 5.21287 23.1159C7.2662 22.7185 9.21687 21.8492 10.8729 20.5745Z"
        fill="white"
      />
    </svg>
  )
}
