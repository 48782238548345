import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px -161px 124px black;
  z-index: 3;
`

export function Shadow() {
  return <Container></Container>
}
