export function KalaoIcon({ height = '262.5', color = '#FFFFFF' }: { height?: string; color?: string }) {
  return (
    <svg height={height} overflow="initial" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 262.5">
      <g>
        <g>
          <path
            fill={color}
            d="M281.9,139.3h5.9l31.8-35h33.8L316.5,145l39,56.7H323l-25.4-36.2h-15.7v36.2h-28.3V57.3h28.3V139.3z"
          />
          <path
            fill={color}
            d="M406.6,101.2c12.9,0,31.4,1.6,55.5,4.7v95.8h-28.2v-7c-8.7,6.7-18.4,10-29.3,10c-13.8,0-25-4.8-33.8-14.3
			c-8.7-9.5-13-22.1-13-38c0.3-15.9,4.8-28.4,13.4-37.5S391.5,101.2,406.6,101.2z M411,124.7c-17.1,0-25.6,9.6-25.6,28.7
			c0,8.3,2.2,15,6.6,20.1c4.4,5.1,10.1,7.7,17.3,7.7c8.5,0,16.7-3.2,24.6-9.5v-45.8C425.1,125.1,417.5,124.7,411,124.7z"
          />
          <path fill={color} d="M518.6,57.3v144.4h-28.3V57.3H518.6z" />
          <path
            fill={color}
            d="M588.7,101.2c12.9,0,31.4,1.6,55.5,4.7v95.8H616v-7c-8.7,6.7-18.4,10-29.3,10c-13.8,0-25-4.8-33.8-14.3
			c-8.7-9.5-13-22.1-13-38c0.3-15.9,4.8-28.4,13.4-37.5S573.7,101.2,588.7,101.2z M593.2,124.7c-17.1,0-25.6,9.6-25.6,28.7
			c0,8.3,2.2,15,6.6,20.1c4.4,5.1,10.1,7.7,17.3,7.7c8.5,0,16.7-3.2,24.6-9.5v-45.8C607.3,125.1,599.7,124.7,593.2,124.7z"
          />
          <path
            fill={color}
            d="M719.7,100c16.4,0,29.6,4.8,39.6,14.4c10.1,9.7,15.1,22.3,15.1,37.8c0,15.8-5,28.6-15.1,38.4
			c-10,9.8-23.2,14.7-39.6,14.7c-16.4,0-29.7-4.9-39.9-14.7c-10.1-9.7-15.2-22.5-15.2-38.4c0-15.6,5-28.2,15-37.8
			C689.8,104.8,703.1,100,719.7,100z M746.5,152.3c0-8.1-2.4-14.7-7.3-19.6c-4.9-4.9-11.4-7.4-19.5-7.4s-14.6,2.5-19.6,7.4
			c-5,4.9-7.5,11.5-7.5,19.6c0,8.3,2.5,15,7.5,20.2s11.6,7.7,19.6,7.7c8,0,14.5-2.6,19.4-7.7C744,167.3,746.5,160.6,746.5,152.3z"
          />
        </g>
        <g>
          <g>
            <path
              fill={color}
              d="M155,133l0.3,0.3l2.7,2.7l0,0l36.3,36.3c3.8,3.8,5.8,10.2,5.4,17.2c-0.4,7.5-3.5,14.6-8.4,19.4
				c-5.2,5.2-13.1,8.4-21.1,8.4c-6.4,0-12-2-15.5-5.4L115.8,173l-0.1,0L155,133 M155,127.6L110.4,173l2.7,2.7l38.8,38.8
				c4.3,4.3,11,6.6,18.2,6.6c8.4,0,17.4-3.1,23.8-9.5c11.8-11.8,12.4-32.6,3-42.1L158,130.6l0,0L155,127.6L155,127.6z"
            />
            <path
              fill={color}
              d="M170.1,44c8,0,16,3.1,21.2,8.4c4.9,4.9,7.9,12,8.3,19.4c0.4,7-1.6,13.4-5.4,17.2l-38.6,38.9L116,88l0,0
				l38.6-38.6C158.1,45.9,163.7,44,170.1,44 M170.1,40.2c-7.1,0-13.8,2.2-18.1,6.5l-38.8,38.8l-2.2,2.8l44.7,44.9l41.3-41.6
				c9.4-9.4,8.9-30.2-2.9-42C187.6,43.3,178.5,40.2,170.1,40.2L170.1,40.2z"
            />
            <path
              fill={color}
              d="M107.5,170.1L68,130.6l45-45l0.1-0.1l0.2,0.2l-0.2-0.2L84.8,57.1c0,0-15-16.8-29.7-16.8
				c-16.7,0-31.9,14.3-31.9,27.6v125.9c0,13.3,15.1,27.6,31.8,27.6c14.8,0,29.7-17.1,29.7-17.1l28.4-28.6l-0.1-0.1L107.5,170.1z"
            />
          </g>
          <path
            fill={color}
            d="M113.1,90.9l39.5,39.7l-2.9,2.9l-36.6,36.9l-2.9-2.9l-36.8-36.8L113.1,90.9 M113.1,85.5l-0.1,0.1l-45,45
			l39.5,39.5l5.5,5.5l0.1,0.1l0.2-0.2l39.1-39.4l5.5-5.5l0,0l-44.7-44.9L113.1,85.5L113.1,85.5z"
          />
        </g>
      </g>
    </svg>
  )
}
