export const highestBidsQuery = `
query {
  highestBidEntities (orderBy: amount){
      bidder
      amount
      bidtime
    }
}
`

export const biddersQuery = `
query {
  bidderEntities(orderBy: amount, orderDirection: desc) {
    id
    bidder
    amount
    bidtime
    withdrawn
  }
}
`

export const withdrawalsQuery = `
query {
    bidWithdrawnEntities(first: 5 ) {
        id
        bidder
        withdrawTime
      }
}
`
// // Example Query:
// function queryHighestBids() {
//     client
//       .query({
//         query: gql(highestBidsQuery),
//       })
//       .then((data) => console.log('Subgraph data: ', data))
//       .catch((err) => {
//         console.log('Error fetching data: ', err)
//       })