import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'

const link = new HttpLink({
  uri:
    process.env.REACT_APP_NETWORKID === '1'
      ? process.env.REACT_APP_AUCTION_GRAPH_URL
      : process.env.REACT_APP_TEST_AUCTION_GRAPH_URL,
})

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})
