import { HF, VF } from './common/Layout'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import styled from 'styled-components'
import { TitleBig, Typography, TypographyColor } from './common/Typography'
import { LinkColor } from './common/Link'
import background from '../assets/about/image-68.png'

interface AboutProps {
  id?: string
}

const Card = styled(HF)`
  min-height: 1020px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
`

const TextArea = styled(VF)`
  flex: 1;
`

const Left = styled(TextArea)`
  padding-right: 15px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-right: 0;
  `};
`
const Right = styled(TextArea)`
  padding-left: 15px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-left: 0;
  `};
`

const Textbody = styled(Typography)`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  margin-top: 10px;
`

export function About({ id }: AboutProps) {
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()

  return (
    <Card
      width={'100%'}
      margin={upToSmall ? '0 0 0' : upToMedium ? '0 0 0' : '0 0 0'}
      padding={upToSmall ? '0 15px 100px' : upToMedium ? '0 30px 100px' : '0 30px 100px'}
      id={id}
      style={{ backgroundImage: `url(${background})` }}
    >
      <VF width={'100%'} align={'flex-start'} justify={'flex-end'}>
        <TitleBig color={TypographyColor.WHITE}>About Artist</TitleBig>
        <HF direction={upToSmall ? 'column' : 'row'} padding={'10px 0 0'}>
          <Left>
            <Textbody color={TypographyColor.WHITE} weight={400} size={'14px'}>
              <Typography color={TypographyColor.WHITE}>
                <p>jason m peterson is an american photographer and creative director.</p>
              </Typography>
              <Typography color={TypographyColor.WHITE}>
                <p>
                  A master of black and white street photography. Jason has been honing his craft for over 30 years.
                </p>
              </Typography>
              <Typography color={TypographyColor.WHITE}>
                <p>
                  Drawing inspiration from urban landscape and the human condition within it, Jason manages to capture
                  moments that are sharp in clarity and alluring in contrast. Negative space is used effortlessly and
                  the faceless subjects flow perfectly in each image.
                </p>
              </Typography>
              <Typography color={TypographyColor.WHITE}>
                <p>
                  Influenced by iconic photographers of the past, Jason creates smooth and powerful compositions that
                  breed a dark and mysterious graphic fantasy.
                </p>
              </Typography>
            </Textbody>
          </Left>
          <Right>
            <Textbody color={TypographyColor.WHITE} weight={400} size={'14px'}>
              <Typography color={TypographyColor.WHITE}>
                <p>
                  As a creative director for more than 25 years, Jason has been igniting cultural conversations across
                  all media. Jason is responsible for creating and overseeing landmark campaigns for Yeezy, NBA, Coke,
                  Dos Equis, Heineken, Ford, McDonald’s, StateFarm, Boost Mobile and many more. Jason has most recently
                  launched The Times (<LinkColor to={'wearethetimes.com'}>wearethetimes.com</LinkColor>) a culture first
                  advertising agency located in Chicago.
                </p>
              </Typography>
              <Typography color={TypographyColor.WHITE}>
                <p>
                  Beyond mainstream advertising brand building, Jason has been tapped by some of the world’s leading
                  brands (e.g. Adidas, Perrier, Dom Perignon, NHL, Chicago Bulls, Bloomingdales and Volvo), as well as,
                  musicians (e.g. ASAP Rocky, Kanye West, Lupe Fiasco, G Eazy, Chance the Rapper and Diplo) to
                  collaborate on social campaigns based on his signature black and white photos and 1M+ Instagram
                  followers (<LinkColor to={'https://www.instagram.com/jasonmpeterson/'}>@jasonmpeterson</LinkColor>).
                </p>
              </Typography>
            </Textbody>
          </Right>
        </HF>
      </VF>
    </Card>
  )
}
