import { Button } from 'rebass/styled-components'
import styled from 'styled-components'

export const Base = styled(Button)<{
  width?: string
  margin?: string
  height?: string
  maxHeight?: string
  minHeight?: string
  minWidth?: string
  padding?: string
  fontSize?: string
}>`
  cursor: ${({ theme }) => theme.buttons.cursor};
  width: ${({ width }) => width ?? 'auto'};
  min-width: ${({ minWidth }) => minWidth ?? '146px'};
  height: ${({ height }) => height ?? '46px'};
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight};` : '')}
  min-height: ${({ minHeight }) => minHeight ?? '0'};
  margin: ${({ margin }) => margin ?? '0'};
  border: solid 1px transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-size: ${({ theme, fontSize }) => fontSize ?? theme.buttons.fontSize};
  padding: ${({ theme, padding }) => padding ?? theme.buttons.padding};
  text-decoration: none;
  &[disabled] {
    background-color: ${({ theme }) => `${theme.colors.background} !important`};
    border-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
  }
`

export const Button1 = styled(Base)`
  background-color: ${({ theme }) => theme.buttons.primary.bg};
  border: ${({ theme }) => theme.buttons.primary.border};
  color: ${({ theme }) => theme.buttons.primary.color};
  border-radius: ${({ theme }) => theme.buttons.primary.borderRadius};
  font-weight: ${({ theme }) => theme.buttons.fontWeight};
  &:hover {
    background-color: ${({ theme }) => theme.buttons.primary.bgHover};
  }
  &:active,
  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.buttons.primary.bgHover};
  }
  &[disabled] {
    background-color: ${({ theme }) => `${theme.colors.disabled} !important`};
    color: ${({ theme }) => theme.buttons.primary.colorDisabled};
    border: none;
    cursor: not-allowed;
  }
`

export const Button2 = styled(Base)`
  background-color: ${({ theme }) => theme.buttons.secondary.bg};
  border: ${({ theme }) => theme.buttons.secondary.border};
  color: ${({ theme }) => theme.buttons.secondary.color};
  border-radius: ${({ theme }) => theme.buttons.secondary.borderRadius};
  font-weight: ${({ theme }) => theme.buttons.fontWeight};
  &:hover {
    background-color: ${({ theme }) => theme.buttons.secondary.bgHover};
  }
  &:active,
  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.buttons.secondary.bgHover};
  }
  &[disabled] {
    background-color: ${({ theme }) => `${theme.buttons.secondary.bg} !important`};
    border-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.buttons.secondary.colorDisabled};
    cursor: not-allowed;
  }
`

export const Button3 = styled(Base)`
  background-color: ${({ theme }) => theme.buttons.tertiary.bg};
  border: ${({ theme }) => theme.buttons.tertiary.border};
  color: ${({ theme }) => theme.buttons.tertiary.color};
  border-radius: ${({ theme }) => theme.buttons.tertiary.borderRadius};
  font-weight: ${({ theme }) => theme.buttons.fontWeight};
  &:hover {
    background-color: ${({ theme }) => theme.buttons.tertiary.bgHover};
  }
  &:active,
  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.buttons.tertiary.bgHover};
  }
  &[disabled] {
    color: ${({ theme }) => theme.buttons.tertiary.colorDisabled};
    background-color: ${({ theme }) => `${theme.buttons.tertiary.bgDisabled} !important`};
    border: none;
    cursor: not-allowed;
  }
`
