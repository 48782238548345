import styled from 'styled-components'
import { HF } from './common/Layout'

export const NFTImageContainer = styled(HF)`
  // border: 1px solid pink;
  aspect-ratio: 1044/1044;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;  
  `};
`

export const NFTImageContainer_Mural = styled(NFTImageContainer)`
  ${({ theme }) => theme.mediaWidth.upToSmall`    
    aspect-ratio: 1044/806;
  `};
`

export const NFTImageContainer_Carousel = styled(NFTImageContainer)`
  max-width: calc(600px - 168px);

  ${({ theme }) => theme.mediaWidth.upToSmall`
  `};
`

export const NFTImage = styled.img`
  max-width: 100%;
  max-height: 100%;

  // border: 5px solid yellow;
`
