import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/client'
import { client } from './graphql/client'
import { ApplicationContextProvider } from './contexts/application.context'
import Web3ReactManager from './components/Web3ReactManager/Web3ReactManager'
import { UITheme } from './styles/theme'
import { Web3ReactProvider } from '@web3-react/core'
import getLibrary from './utils/getLibrary'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client = {client}>
      <ThemeProvider theme={UITheme}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ApplicationContextProvider>
            <Web3ReactManager allowInactiveNetwork={true}>
              <BrowserRouter>
                {/*
                  A <Switch> looks through all its children <Route>
                  elements and renders the first one whose path
                  matches the current URL. Use a <Switch> any time
                  you have multiple routes, but you want only one
                  of them to render at a time
                */}
                <App />
              </BrowserRouter>
            </Web3ReactManager>
          </ApplicationContextProvider>
        </Web3ReactProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
