import { darken } from 'polished'
import { css } from 'styled-components'

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
  upToLarger: 1600,
  upToXtraLarge: 1800,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const useMediaTemplates = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  return {
    ...accumulator,
    [size]: { maxWidth: `${(MEDIA_WIDTHS as any)[size]}px` },
  }
}, {})

export const UITheme = {
  colors: {
    text: '#000000',
    textLight: '#1A1A1C',
    textExtraLight: '#6c6c6e',
    black: '#000000',
    background: '#fff',
    backgroundSecondary: '#F3F3F3',
    primary: '#FF3242',
    secondary: '#f8b26a',
    muted: '#f6f6f9',
    gray: '#AFB3C2',
    line: '#F1F1F4',
    disabled: '#656874',
    darkGray: '#33353a',

    gray900: '#212121',
    gray800: '#424242',
    gray500: '#9e9e9e',
    gray300: '#e0e0e0',
    gray200: '#f5f5f5',
    red600: '#DF3B57', // avalanche red
    red500: '#e84142', // avalanche red
    orange500: '#F5841F', // avalanche red
    white: '#fff',
    highlight: 'hsla(205, 100%, 40%, 0.125)',
  },
  mediaWidth: mediaWidthTemplates,
  useMediaWidth: useMediaTemplates,
  fontFamily:
    'Inter, ui-sans-serif, system-ui, -apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  fontSize: '14px',
  lineHeight: '20px',
  shadows: {
    card: '0 0 4px rgba(0, 0, 0, .125)',
  },
  // rebass variants
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [5, 6, 7],
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
  },
  variants: {
    avatar: {
      width: 'avatar',
      height: 'avatar',
      borderRadius: 'circle',
    },
    card: {
      p: 2,
      bg: 'background',
      boxShadow: 'card',
    },
    link: {
      color: 'primary',
    },
    nav: {
      fontSize: 1,
      fontWeight: 'bold',
      display: 'inline-block',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary',
      },
    },
  },
  buttons: {
    cursor: 'pointer',
    padding: '8px 16px',
    fontSize: '18px',
    fontWeight: '600',
    primary: {
      color: 'white',
      colorDisabled: 'white',
      bg: '#FF3242',
      bgHover: darken(0.1, '#FF3242'),
      border: 'none',
      borderRadius: '8px',
    },
    secondary: {
      color: '#000000',
      colorDisabled: '#FF3242',
      bg: 'transparent',
      bgHover: 'rgba(255, 255, 255, 0.1)',
      border: '1px solid #000000',
      borderRadius: '8px',
    },
    tertiary: {
      color: '#FF3242',
      colorDisabled: '#FF3242',
      bg: '#ffffff',
      bgHover: 'rgba(255, 255, 255, 0.9)',
      bgDisabled: 'rgba(255, 255, 255, 0.9)',
      border: '0',
      borderRadius: '8px',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
  },
}
