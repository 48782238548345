import { BigNumber, ethers } from 'ethers'
import moment from 'moment'

const prefix = 'https://twitter.com/intent/tweet?text='
const auctionURL = 'yeahprobablynothing.com'

export function twitterIntent(winAmount: BigNumber, endTime: Date | undefined) {
  return `${prefix}The%20highest%20bid%20for%20the%20Jason%20Peterson%20Mural%20NFT%20Auction%20is%20${Number(
    ethers.utils.formatEther(winAmount)
  )}%20AVAX.${getTimeRemaining(endTime)}%20%20${auctionURL}`
}

export function twitterIntentBid(bidAmount: BigNumber, endTime: Date | undefined) {
  return `${prefix}I%20just%20bid%20${Number(
    ethers.utils.formatEther(bidAmount)
  )}%20AVAX%20on%20the%20Jason%20Peterson%20Mural%20NFT%20Auction.${getTimeRemaining(endTime)}%20%20${auctionURL}`
}

function getTimeRemaining(endTime: Date | undefined) {
  let timeRemaining = ''
  if (endTime) {
    let msRemaining = endTime.getTime() - new Date().getTime()
    if (msRemaining > 0) {
      timeRemaining = `%20Only%20${moment.duration(msRemaining).humanize()}%20remaining.`
    }
  }
  return timeRemaining
}
