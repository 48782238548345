import muralFinal from '../assets/4153079N8739049W.jpg'
import { NFTImage, NFTImageContainer_Mural as NFTMural } from './NFTImage'

export function Mural() {
  return (
    <NFTMural width={'100%'} justify={'space-between'} align="center">
      <NFTImage src={muralFinal} loading="lazy" alt="JMP Mural NFT" />
    </NFTMural>
  )
}
