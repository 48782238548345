import { useRef, useState, useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { useAuction } from './hooks/useAuction'
import { usePack } from './hooks/usePack'
import { Navbar } from './components/Navbar'
import Landing from './pages/Landing'
import Auction from './pages/Auction'
import Pack from './pages/Pack'
import moment from 'moment'
import styled from 'styled-components'
import './Swal.css'
import { BigNumber } from 'ethers'

const ContentContainer = styled.div<{
  padding?: string
}>`
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
`

function App() {
  // GLOBALS + ROUTING
  const location = useLocation()
  const [contentPadding, setContentPadding] = useState<string>('')
  const headerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setContentPadding(`${headerRef.current?.offsetHeight || 0}px 0 0 0`)
  }, [headerRef, location?.pathname])

  // AUCTION
  const auctionContractAddress =
    process.env.REACT_APP_NETWORKID === '1'
      ? (process.env.REACT_APP_AUCTION_CONTRACT_ADDRESS as string)
      : (process.env.REACT_APP_TEST_AUCTION_CONTRACT_ADDRESS as string)
  const auctionInfo = useAuction(auctionContractAddress)
  const afterEndTime = auctionInfo.auctionEndTime ? moment(auctionInfo.auctionEndTime).isBefore() : undefined
  const afterClaimTime = auctionInfo.auctionClaimTime ? moment(auctionInfo.auctionClaimTime).isBefore() : undefined
  const startingBid = auctionInfo.auctionStartingPrice.add(auctionInfo.auctionMinBidIncrement)
  const nextMinBid = auctionInfo.auctionHighestBid.gt(0)
    ? afterEndTime
      ? auctionInfo.auctionHighestBid.add(auctionInfo.auctionHighestBid.mul(30).div(100))
      : auctionInfo.auctionHighestBid.add(auctionInfo.auctionMinBidIncrement)
    : startingBid

  // PACKS
  const [artPackContractAddress, artistPackContractAddress] =
    process.env.REACT_APP_NETWORKID === '1'
      ? [
          process.env.REACT_APP_PACK_ART_CONTRACT_ADDRESS as string,
          process.env.REACT_APP_PACK_ARTIST_CONTRACT_ADDRESS as string,
        ]
      : [
          process.env.REACT_APP_TEST_PACK_ART_CONTRACT_ADDRESS as string,
          process.env.REACT_APP_TEST_PACK_ARTIST_CONTRACT_ADDRESS as string,
        ]

  const [artPackInfo, artistPackInfo] = [
    usePack(artPackContractAddress, auctionInfo.walletIsConnected),
    usePack(artistPackContractAddress, auctionInfo.walletIsConnected),
  ]

  const packPrice = artistPackInfo.price ? artistPackInfo.price : BigNumber.from(0)

  return (
    <>
      <Navbar
        ref={headerRef}
        disconnect={auctionInfo.disconnect}
        balance={auctionInfo.walletBalance}
        nextMinBid={nextMinBid}
        packPrice={packPrice}
        walletCurrentBid={auctionInfo.walletCurrentBid}
      />
      <ContentContainer padding={contentPadding}>
        <Switch>
          <Route exact path="/">
            <Landing
              auctionInfo={auctionInfo}
              afterEndTime={afterEndTime}
              afterClaimTime={afterClaimTime}
              nextMinBid={nextMinBid}
              startingBid={startingBid}
              artPack={artPackInfo}
              artistPack={artistPackInfo}
            />
          </Route>
          <Route exact path="/auction">
            <Auction
              contractAddress={auctionContractAddress}
              auctionInfo={auctionInfo}
              afterEndTime={afterEndTime}
              afterClaimTime={afterClaimTime}
              startingBid={startingBid}
              nextMinBid={nextMinBid}
              walletIsConnected={auctionInfo.walletIsConnected}
            />
          </Route>
          <Route exact path="/pack/artist">
            <Pack
              contractAddress={artistPackContractAddress}
              packInfo={artistPackInfo}
              walletIsConnected={auctionInfo.walletIsConnected}
              balance={auctionInfo.walletBalance}
            />
          </Route>
          <Route exact path="/pack/art">
            <Pack
              contractAddress={artPackContractAddress}
              packInfo={artPackInfo}
              walletIsConnected={auctionInfo.walletIsConnected}
              balance={auctionInfo.walletBalance}
            />
          </Route>
        </Switch>
      </ContentContainer>
    </>
  )
}

export default App
