import { HF, VF } from './common/Layout'
import styled from 'styled-components'
import { Typography, Title2 } from './common/Typography'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import { LinkIcon } from './icons/Link'
import { LinkColor } from './common/Link'

interface MuralProps {
  address: string | undefined
  id: string | undefined
}

export const MuralContainer = styled(HF)`
  width: 1044px;
  max-width: 100%;
  aspect-ratio: 1044/806;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `};
`

const Textbody = styled(Typography)`
  margin-top: 10px;
`

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export function MuralDescription({ address, id }: MuralProps) {
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()

  const explorerURL =
    process.env.REACT_APP_NETWORKID === '1'
      ? process.env.REACT_APP_EXPLORER_URL
      : process.env.REACT_APP_TEST_EXPLORER_URL

  return (
    <VF
      width={'100%'}
      align={'center'}
      margin={upToSmall ? '32px 0 20px' : upToMedium ? '0 0 20px' : '0 0 20px'}
      padding={upToSmall ? '0 15px' : upToMedium ? '0 30px' : '0'}
    >
      <VF align="flex-start" padding={upToSmall ? '0' : '0'} width="100%">
        <Title2>41°53'07.9"N 87°39'04.9"W Mural NFT</Title2>
        <Textbody>
          The 41°53'07.9"N 87°39'04.9"W Mural NFT, created by{' '}
          <ExternalLink href="https://www.instagram.com/jasonmpeterson/" target="_blank">
            Jason M Peterson
          </ExternalLink>{' '}
          in collaboration with{' '}
          <ExternalLink href={'https://www.instagram.com/tubsz_illa/'} target="_blank">
            @tubzilla
          </ExternalLink>
          , ties a physical mural of Kanye West located in Chicago on Lake Street in the West Loop. This mural is based
          on a photographic portrait of Kanye West shot by Jason.
        </Textbody>
        <Textbody>
          The Mural NFT commemorates the moment in time in which the Jason M Peterson mural existed in the West Loop,
          visited by over 55 million of Chicago’s annual visitors. On-site, visitors of the physical mural will be able
          to scan the QR code verifying the ownership of the NFT.
        </Textbody>
        <Textbody>
          Minted on the{' '}
          <ExternalLink href={'https://avax.network/'} target="_blank">
            Avalanche
          </ExternalLink>{' '}
          blockchain, the 41°53'07.9"N 87°39'04.9"W Mural NFT is a one-of-a-kind NFT that exists across both physical
          and digital worlds.
        </Textbody>
        <Textbody>
          <LinkColor
            to={{
              pathname: `${explorerURL}${process.env.REACT_APP_ERC721_BASE_URL}${address}/nft/${id}`,
            }}
            target="_blank"
          >
            <LinkIcon style={{ marginRight: '6px' }} />
            View the NFT
          </LinkColor>
        </Textbody>
      </VF>
    </VF>
  )
}
