import { Link } from 'react-router-dom'
import { HF, VF } from './common/Layout'
import styled from 'styled-components'
import { TitleBig, TypographyColor } from './common/Typography'
import { Button1 } from './common/Button'

import artist from '../assets/artist-sm.jpg'
import artistSquare from '../assets/artist-sm-square.jpg'
import art from '../assets/art-sm.jpg'
import artSquare from '../assets/art-sm-square.jpg'

import { usePack } from '../hooks/usePack'
import { DisplayPrice } from './common/DisplayPrice'
import { PackCount } from '../components/PackCount'
import { getPackCount } from '../utils/getPackCount'
import { Shadow } from './Shadow'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'

interface PacksCardProps {
  title: string
  id: string
  pack: ReturnType<typeof usePack>
}

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  object-fit: cover;
`

const Card = styled(VF)`
  min-height: 562px;

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;

  position: relative;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-height: 600px;
  `};
`

const Content = styled(VF)`
  z-index: 10;
`

export function PackCard({ title, pack, id }: PacksCardProps) {
  const { maxPacks, packsMinted, price } = pack
  const packCount = getPackCount(packsMinted, maxPacks)
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()

  const artistImg = upToSmall ? artist : artistSquare
  const artImg = upToSmall ? art : artSquare
  const src = id === 'artist' ? artistImg : artImg

  return (
    <Card
      margin={upToSmall ? '0 0 0' : upToMedium ? '0 0 0' : '0 0 0'}
      padding={upToSmall ? '0 15px 100px' : upToMedium ? '0 30px 100px' : '30px'}
      width={'100%'}
      align={'flex-start'}
      justify={'flex-end'}
    >
      <Content width={'100%'} align={'flex-start'} justify={'flex-end'}>
        <TitleBig color={TypographyColor.WHITE}>{title}</TitleBig>
        <HF width={'100%'} align={'center'} margin={'10px 0 0'}>
          {maxPacks ? <PackCount packsLeft={packCount} maxPacks={maxPacks.toString()} /> : '-'}
          {price ? (
            <DisplayPrice
              padding={'0 0 0 16px'}
              size={'14'}
              color={TypographyColor.WHITE}
              price={price}
              showAvax={false}
            />
          ) : (
            '-'
          )}
        </HF>
        <Link to={`/pack/${id}`} style={{ textDecoration: 'none' }}>
          <Button1 margin={'24px 0'}>Buy</Button1>
        </Link>
      </Content>
      <BackgroundImage src={src} />
      <Shadow />
    </Card>
  )
}
