import styled from 'styled-components'
import { WeightTypes } from './types'

export enum TypographyColor {
  WHITE = 'white',
  LIGHT = 'light',
  MEDIUM = 'medium',
  DARK = 'dark',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export const Typography = styled.span<{
  fontFamily?: string
  weight?: WeightTypes
  size?: string
  align?: string
  color?: TypographyColor
  lineHeight?: string
  margin?: string
  padding?: string
  wrap?: string
  width?: string
  overflow?: string
  textOverflow?: string
}>`
  font-family: ${({ theme, fontFamily }) => (fontFamily ? fontFamily : theme.fontFamily)};
  font-weight: ${({ weight }) => weight ?? 400};
  font-size: ${({ theme, size }) => (size ? `${size}` : theme.fontSize)};
  text-align: ${({ align }) => align ?? 'left'};
  color: ${({ color, theme }) => {
    if (color === TypographyColor.WHITE) {
      return theme.colors.white
    } else if (color === TypographyColor.LIGHT) {
      return theme.colors.textExtraLight
    } else if (color === TypographyColor.MEDIUM) {
      return theme.colors.text
    } else if (color === TypographyColor.DARK) {
      return theme.colors.black
    } else if (color === TypographyColor.PRIMARY) {
      return theme.colors.primary
    } else if (color === TypographyColor.SECONDARY) {
      return theme.colors.secondary
    }
    return theme.colors.text
  }};
  line-height: ${({ theme, lineHeight }) => (lineHeight ? lineHeight : theme.lineHeight)};
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ overflow }) => (overflow ? `overflow: ${overflow};` : '')}
  ${({ textOverflow }) => (textOverflow ? `text-overflow: ${textOverflow};` : '')}
  ${({ wrap }) => (wrap ? `white-space: ${wrap};` : '')}
`

export const ErrorTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.red500};
`

export const WarningTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.orange500};
`
export const SubTextTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray500};
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
`

export const Title = styled(Typography)`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    line-height: 23px;
  `};
`

export const TitleBig = styled(Title)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 36px;
    line-height: 44px;
  `};
`

export const Title2 = styled(Title)`
  font-size: 24px;
  line-height: 29px;
`

export const Title3 = styled(Title)`
  margin-top: 24px;
  font-size: 14px;
  line-height: 17px;
`

export const Announcement = styled(Typography)`
  margin-top: 24px;
  font-family: Yeseva One;
  font-style: normal;
  font-size: 24px;
  line-height: 29px;

  ${({ theme }) => theme.mediaWidth.upToSmall``};
`
export const Price = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 24px;
  line-height: 29px;
  `};
`

export const SmallPrice = styled(Price)`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  font-size: 24px;
  line-height: 29px;
  `};
`

export const Label = styled(Typography)<{
  color?: TypographyColor
}>`
  color: ${({ color, theme }) => {
    if (color === TypographyColor.WHITE) {
      return theme.colors.white
    } else if (color === TypographyColor.LIGHT) {
      return theme.colors.textExtraLight
    } else if (color === TypographyColor.MEDIUM) {
      return theme.colors.text
    } else if (color === TypographyColor.DARK) {
      return theme.colors.black
    } else if (color === TypographyColor.PRIMARY) {
      return theme.colors.primary
    } else if (color === TypographyColor.SECONDARY) {
      return theme.colors.secondary
    }
    return theme.colors.text
  }};
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
`
export const Label2 = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.textExtraLight};
`

export const Emphasis = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  padding-top: 8px;
`
