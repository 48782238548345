import styled from 'styled-components'

const FlexBase = styled.div<{
  justify?: string
  align?: string
  margin?: string
  padding?: string
  width?: string
  height?: string
  flex?: number
  position?: string
  inset?: string
  shrink?: number
  wrap?: string
  grow?: string
  id?: string
}>`
  display: flex;
  justify-content: ${({ justify }) => justify ?? 'initial'};
  align-items: ${({ align }) => align ?? 'initial'};
  padding: ${({ padding }) => padding ?? '0px'};
  margin: ${({ margin }) => margin ?? '0px'};
  width: ${({ width }) => width ?? 'auto'};
  height: ${({ height }) => height ?? 'initial'};
  flex: ${({ flex }) => flex ?? 'initial'};
  ${({ position }) => {
    return position ? `position: ${position};` : ''
  }}
  ${({ inset }) => {
    return inset ? `inset: ${inset};` : ''
  }}
  ${({ shrink }) => {
    return shrink ? `flex-shrink: ${shrink};` : ''
  }}
  ${({ wrap }) => {
    return wrap ? `flex-wrap: ${wrap};` : ''
  }}
  ${({ grow }) => {
    return grow ? `flex-grow: ${grow};` : ''
  }}
`

export const HF = styled(FlexBase)<{
  direction?: string
}>`
  flex-direction: ${({ direction }) => direction ?? 'row'};
`

export const VF = styled(FlexBase)<{
  direction?: string
}>`
  flex-direction: ${({ direction }) => direction ?? 'column'};
`

export const Content = styled(VF)<{
  minHeight?: string
  marginLR?: string
}>`
  max-width: 1240px;
  margin-left: ${({ marginLR }) => marginLR ?? 'auto'};
  margin-right: ${({ marginLR }) => marginLR ?? 'auto'};

  ${({ minHeight }) => {
    return minHeight ? `min-height: ${minHeight};` : ''
  }}

  ${({ theme }) => theme.mediaWidth.upToMedium`        
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`        
  `};
`

export const TwoColumn = styled(HF)`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-columns: 50% 50%;

  ${({ theme }) => theme.mediaWidth.upToSmall`    
    grid-template-columns: 1fr;
    grid-auto-flow: initial;
  `};
`

export const LeftContainer = styled(VF)`
  margin-right: 20px;
  flex-grow: 1;
  // border: 1px solid rgba(0, 0, 255, 0.25);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
  `};
`
export const RightContainer = styled(VF)`
  margin-left: 20px;
  flex-grow: 1;
  padding: 0 60px;
  // border: 1px solid rgba(0, 0, 255, 0.25);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;  
    padding: 0;
  `};
`
