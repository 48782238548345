import { HF } from './common/Layout'
import { PackCard } from './PackCard'
import { usePack } from '../hooks/usePack'
import { useGetIsMobileScreenSize } from '../styles/useGetIsMobileScreenSize'
import { ForwardedRef, forwardRef } from 'react'

interface ParksHeroProps {
  artPack: ReturnType<typeof usePack>
  artistPack: ReturnType<typeof usePack>
}
function PacksHeroInner({ artPack, artistPack }: ParksHeroProps, ref: ForwardedRef<HTMLDivElement>) {
  const { upToSmall, upToMedium } = useGetIsMobileScreenSize()

  return (
    <HF
      ref={ref}
      direction={upToSmall ? 'column' : upToMedium ? 'row' : 'row'}
      width={'100%'}
      align={'center'}
      margin={upToSmall ? '0 0 0' : upToMedium ? '0 0 150px' : '0 0 150px'}
    >
      <PackCard id={'artist'} title={'Artist Pack'} pack={artistPack} />
      <PackCard id={'art'} title={'Art Pack'} pack={artPack} />
    </HF>
  )
}

export const PacksHero = forwardRef(PacksHeroInner)
