import { VF, HF } from '../common/Layout'
import { WarningIcon } from '../icons/Warning'

export function BasicError({ children }: { children?: any }) {
  return (
    <HF align={'center'} justify={'center'} height={'100%'}>
      <VF align={'center'} justify={'center'} height={'100%'}>
        <WarningIcon height={'48px'} />
        <br />
        {children}
      </VF>
    </HF>
  )
}
